import {getRequest} from "../routes/Routes";
import {sessionService} from 'redux-react-session';

export const loadBranches = async () => {
    let categories = [];
    await getRequest('/api/branches').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                categories = response.data.branches;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return categories;
};

export const getBranch = async () => {
    return await sessionService.loadSession().then((session) => {
        return session.branch;
    }).catch(() => {
        return null;
    });
};

export const updateBranch = async (branch, history) => {
    await sessionService.saveSession({branch: branch});
    history.go(
        history.push({pathname: "/address"})
    );
};

export const setBranch = async (branch) => {
    let currentBranch = null;
    await getBranch().then(response => {
        currentBranch = response;
    });
    if (!currentBranch) {
        sessionService.saveSession({branch: branch});
    }
};

export const loadBranch = async (id, token) => {
    let branch = null;
    await getRequest('/api/branches/' + id, token).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                branch = response.data.branch;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return branch;
};

export const loadBranchFoodTypes = async (id) => {
    let foodTypes = [];
    await getRequest('/api/food-types/branch/' + id).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                response.data.foodTypes.forEach((foodType) => {
                    foodType.selected = false;
                    foodTypes.push(foodType);
                });
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return foodTypes;
};

export const loadBranchDeliveries = async (id) => {
    let deliveries = [];
    await getRequest('/api/deliveries/branch/' + id).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                deliveries = response.data.deliveries;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return deliveries;
};

export const loadBranchOffers = async (id) => {
    let offers = [];
    await getRequest('/api/offers/branch/' + id).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                offers = response.data.offers;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return offers;
};

export const loadBranchBuyGetOffers = async (id) => {
    let buyGetOffers = [];
    await getRequest('/api/buy-get-offers/branch/' + id).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                buyGetOffers = response.data.buyGetOffers;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return buyGetOffers;
};

export const loadFoodTypeDetails = async (id) => {
    let foodType = [];
    await getRequest('/api/food-types/' + id).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                foodType = response.data.foodType;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return foodType;
};

export const loadDeliveryLocationDetails = async (id) => {
    let delivery = null;
    await getRequest('/api/deliveries/' + id).then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                delivery = response.data.delivery;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return delivery;
};

export const updateAddress = async (address) => {
    let currentBranch = null;
    await getBranch().then(response => {
        currentBranch = response;
    });
    sessionService.saveSession({branch: currentBranch, address: address});
};

export const getAddress = async () => {
    return await sessionService.loadSession().then((session) => {
        return session.address;
    }).catch(() => {
        return null;
    });
};

export const getCart = async () => {
    return await sessionService.loadSession().then((session) => {
        return session.cart;
    }).catch(() => {
        return [];
    });
};

export const getPromoCode = async () => {
    return await sessionService.loadSession().then((session) => {
        return session.promoCode;
    }).catch(() => {
        return null;
    });
};

export const getNotes = async () => {
    return await sessionService.loadSession().then((session) => {
        return session.notes;
    }).catch(() => {
        return "";
    });
};

export const addFoodToCart = async (product) => {
    let currentBranch = null,
        currentAddress = null,
        cart = [],
        currentNotes = null,
        buyGetOffers = [];
    await getBranch().then(response => {
        currentBranch = response;
    });
    await getAddress().then(response => {
        currentAddress = response;
    });
    await getCart().then(response => {
        cart = response ? response : [];
    });
    await getNotes().then(response => {
        currentNotes = response;
    });
    await getBuyGetOffers().then(response => {
        buyGetOffers = response;
    });
    let productIndex = cart.findIndex(function (item) {
        return (item.id === product.id && item.type === product.type);
    });
    if (productIndex >= 0) {
        cart[productIndex] = product;
    } else {
        cart.push(product);
    }
    const buyFoodItemGetOfferIndex = buyGetOffers.food.findIndex(function (item) {
        return (item.buy_type_id === product.id);
    });
    if (buyFoodItemGetOfferIndex >= 0) {
        let offer = buyGetOffers.food[buyFoodItemGetOfferIndex],
            food = buyGetOffers.food[buyFoodItemGetOfferIndex].food,
            cartOfferIndex = cart.findIndex(function (item) {
                return (item.id === food.id && item.name === (food.name.includes(' - Free') ? food.name : food.name + ' - Free'));
            });
        if (product.selected_quantity >= offer.buy_quantity) {
            if (cartOfferIndex >= 0) {
                cart[cartOfferIndex].selected_quantity = offer.get_quantity * Math.floor(product.selected_quantity / offer.buy_quantity);
                cart[cartOfferIndex].remaining_quantity = offer.get_quantity * Math.floor(product.selected_quantity / offer.buy_quantity);
            } else {
                food.name = food.name.includes(' - Free') ? food.name : food.name + ' - Free';
                food.price = 0;
                food.selected_quantity = offer.get_quantity * Math.floor(product.selected_quantity / offer.buy_quantity);
                food.remaining_quantity = offer.get_quantity * Math.floor(product.selected_quantity / offer.buy_quantity);
                food.type = "Food";
                cart.push(food);
            }
        } else if (cartOfferIndex >= 0) {
            cart.splice(cartOfferIndex, 1);
        }
    }
    const buySubFoodTypeGetOfferIndex = buyGetOffers.sub_food_types.findIndex(function (item) {
        return (item.buy_type_id === product.sub_food_type_id);
    });
    if (buySubFoodTypeGetOfferIndex >= 0) {
        let offer = buyGetOffers.sub_food_types[buySubFoodTypeGetOfferIndex],
            food = buyGetOffers.sub_food_types[buySubFoodTypeGetOfferIndex].food,
            cartOfferIndex = cart.findIndex(function (item) {
                return (item.id === food.id && item.name === (food.name.includes(' - Free') ? food.name : food.name + ' - Free'));
            }),
            cartSubFoodTypes = cart.filter((item) => item.sub_food_type_id === product.sub_food_type_id && !item.name.includes(' - Free'));
        if (product.selected_quantity >= offer.buy_quantity || cartSubFoodTypes.length >= offer.buy_quantity) {
            if (cartOfferIndex >= 0) {
                let productQuantity = 0;
                cartSubFoodTypes.forEach((item) => {
                    productQuantity += item.selected_quantity;
                });
                if (productQuantity > cartSubFoodTypes.length) {
                    cart[cartOfferIndex].selected_quantity = offer.get_quantity * Math.floor(productQuantity / offer.buy_quantity);
                    cart[cartOfferIndex].remaining_quantity = offer.get_quantity * Math.floor(productQuantity / offer.buy_quantity);
                } else if (cartSubFoodTypes.length >= offer.buy_quantity) {
                    cart[cartOfferIndex].selected_quantity = offer.get_quantity * Math.floor(cartSubFoodTypes.length / offer.buy_quantity);
                    cart[cartOfferIndex].remaining_quantity = offer.get_quantity * Math.floor(cartSubFoodTypes.length / offer.buy_quantity);
                } else {
                    cart[cartOfferIndex].selected_quantity = offer.get_quantity * Math.floor(product.selected_quantity / offer.buy_quantity);
                    cart[cartOfferIndex].remaining_quantity = offer.get_quantity * Math.floor(product.selected_quantity / offer.buy_quantity);
                }
            } else {
                food.name = food.name.includes(' - Free') ? food.name : food.name + ' - Free';
                food.price = 0;
                if (cartSubFoodTypes.length >= offer.buy_quantity) {
                    food.selected_quantity = offer.get_quantity * Math.floor(cartSubFoodTypes.length / offer.buy_quantity);
                    food.remaining_quantity = offer.get_quantity * Math.floor(cartSubFoodTypes.length / offer.buy_quantity);
                } else {
                    food.selected_quantity = offer.get_quantity * Math.floor(product.selected_quantity / offer.buy_quantity);
                    food.remaining_quantity = offer.get_quantity * Math.floor(product.selected_quantity / offer.buy_quantity);
                }
                food.type = "Food";
                cart.push(food);
            }
        } else if (cartOfferIndex >= 0) {
            cart.splice(cartOfferIndex, 1);
        }
    }
    sessionService.saveSession({
        branch: currentBranch,
        address: currentAddress,
        cart: cart,
        notes: currentNotes,
        buyGetOffers: buyGetOffers
    });
};

export const updateCart = async (cart) => {
    let currentBranch = null,
        currentAddress = null,
        currentPromoCode = null,
        currentNotes = null,
        buyGetOffers = [];
    await getBranch().then(response => {
        currentBranch = response;
    });
    await getAddress().then(response => {
        currentAddress = response;
    });
    await getPromoCode().then(response => {
        currentPromoCode = response;
    });
    await getNotes().then(response => {
        currentNotes = response;
    });
    await getBuyGetOffers().then(response => {
        buyGetOffers = response;
    });
    sessionService.saveSession({
        branch: currentBranch,
        address: currentAddress,
        cart: cart,
        promoCode: currentPromoCode,
        notes: currentNotes,
        buyGetOffers: buyGetOffers
    });
};

export const updatePromoCode = async (promoCode, location) => {
    let currentBranch = null,
        currentAddress = null,
        currentCart = null,
        currentNotes = null,
        buyGetOffers = [];
    await getBranch().then(response => {
        currentBranch = response;
    });
    await getAddress().then(response => {
        currentAddress = response;
    });
    await getCart().then(response => {
        currentCart = response;
    });
    await getNotes().then(response => {
        currentNotes = response;
    });
    await getBuyGetOffers().then(response => {
        buyGetOffers = response;
    });
    sessionService.saveSession({
        branch: currentBranch,
        address: currentAddress,
        cart: currentCart,
        promoCode: promoCode,
        notes: currentNotes,
        buyGetOffers: buyGetOffers
    });
    if (promoCode && location !== "Checkout") {
        window.location.reload();
    }
};

export const updateNotes = async (notes) => {
    let currentBranch = null,
        currentAddress = null,
        currentCart = null,
        currentPromoCode = null,
        buyGetOffers = [];
    await getBranch().then(response => {
        currentBranch = response;
    });
    await getAddress().then(response => {
        currentAddress = response;
    });
    await getCart().then(response => {
        currentCart = response;
    });
    await getPromoCode().then(response => {
        currentPromoCode = response;
    });
    await getBuyGetOffers().then(response => {
        buyGetOffers = response;
    });
    sessionService.saveSession({
        branch: currentBranch,
        address: currentAddress,
        cart: currentCart,
        promoCode: currentPromoCode,
        notes: notes,
        buyGetOffers: buyGetOffers
    });
};

export const loadBannerImages = async () => {
    let bannerImages = [];
    await getRequest('/api/banner-images').then(response => {
        if (response.status === 200) {
            if (response.data.error) {
                this.props.alert.error(response.data.message);
            } else {
                bannerImages = response.data.bannerImages;
            }
        } else {
            this.props.alert.error("An error occurred!");
        }
    });
    return bannerImages;
};

export const updateBuyGetOffers = async (buyGetOffers) => {
    let currentBranch = null,
        address = null,
        currentCart = null,
        currentPromoCode = null,
        currentNotes = null;
    await getBranch().then(response => {
        currentBranch = response;
    });
    await getAddress().then(response => {
        address = response;
    });
    await getCart().then(response => {
        currentCart = response;
    });
    await getPromoCode().then(response => {
        currentPromoCode = response;
    });
    await getNotes().then(response => {
        currentNotes = response;
    });
    sessionService.saveSession({
        branch: currentBranch,
        address: address,
        cart: currentCart,
        promoCode: currentPromoCode,
        notes: currentNotes,
        buyGetOffers: buyGetOffers
    });
};

export const getBuyGetOffers = async () => {
    return await sessionService.loadSession().then((session) => {
        return session.buyGetOffers;
    }).catch(() => {
        return null;
    });
};