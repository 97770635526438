import React, {Component} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import {
    getAddress,
    getBranch,
    getBuyGetOffers,
    getCart,
    loadDeliveryLocationDetails,
    updateCart
} from "../common/Common";
import '../assets/css/CartComponent.css';

class CartComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: null,
            cart: null,
            branch: null,
            deliveryLocation: null,
            total: 0,
            subTotal: 0
        };
    }

    async componentDidMount() {
        getBranch().then(branch => {
            this.setState({branch: branch});
        }).catch(() => null);
        await getAddress().then(async address => {
            await loadDeliveryLocationDetails(address.deliveryLocation).then(deliveryLocation => {
                this.setState({address: address, deliveryLocation: deliveryLocation});
            }).catch(() => null);
        }).catch(() => null);
        getCart().then(cart => {
            this.getCartDetails(cart);
        }).catch(() => null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.count !== prevProps.count) {
            getCart().then(cart => {
                this.getCartDetails(cart);
            }).catch(() => null);
        }
    }

    getCartDetails = (cart) => {
        let {deliveryLocation} = this.state;
        let total = 0,
            subTotal = 0;
        total += deliveryLocation ? deliveryLocation.amount : 0;
        if (cart) {
            cart.forEach((food) => {
                total += food.price * food.selected_quantity;
                subTotal += food.price * food.selected_quantity;
            });
        }
        this.setState({cart: cart, total: total, subTotal: subTotal});
    }

    render() {
        if (this.state.branch) {
            if (this.props.isCartPage) {
                return (
                    <div className="cart-container">
                        <div className="row row-cols-2 mobile-cart-header">
                            <div className="col">
                                <h1>My Cart</h1>
                            </div>
                            <div className="col">
                                <button className="btn red-font font-weight-bold"
                                        onClick={this.props.history.goBack}>
                                    &#10006;
                                </button>
                            </div>
                        </div>
                        <h1 className="desktop-cart-header">My Cart</h1>
                        <div className="cart-body-container">
                            {this.state.cart && this.state.cart.length > 0 ? this.orderBody() :
                                <p className="delivery-address">No Products Added</p>
                            }
                        </div>
                        <div className="cart-total-container">
                            <hr className="grey-hr"/>
                            <div className="row row-cols-2 nunito-sans-font font-weight-bold">
                                <div className="col">
                                    <p>
                                        Sub Total
                                    </p>
                                </div>
                                <div className="col">
                                    <p>Rs. {this.state.subTotal}</p>
                                </div>
                            </div>
                            {this.state.address ?
                                <div className="row row-cols-2 nunito-sans-font font-weight-bold">
                                    <div className="col">
                                        <p>
                                            Delivery Charges
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p>Rs. {this.state.deliveryLocation.amount}</p>
                                    </div>
                                </div>
                                : null
                            }
                            <div className="row row-cols-2 nunito-sans-font">
                                <div className="col">
                                    <h4>Total</h4>
                                </div>
                                <div className="col">
                                    <h4>Rs. {this.state.total}</h4>
                                </div>
                            </div>
                            <button className="btn red-button login-button mobile-checkout-button" key="checkoutButton">
                                <NavLink className="nav-link" to="/summary">Continue to Checkout</NavLink>
                            </button>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="cart-container">
                        <div>
                            {this.state.address ? [
                                <h6 key="heading" className="nunito-sans-font font-weight-bold">Delivery Address</h6>,
                                <div className="row row-cols-2 nunito-sans-font" key="address">
                                    <div className="col">
                                        <p className="delivery-address">
                                            {this.state.address.address}
                                        </p>
                                    </div>
                                    <div className="col">
                                        <NavLink className="nav-link red-font" to="/address">
                                            Change Address
                                        </NavLink>
                                    </div>
                                </div>
                            ] : [
                                <h6 key="heading">Takeout/Pickup Location:</h6>,
                                <p key="address" className="delivery-address">
                                    {this.state.branch.name}
                                </p>
                            ]}
                        </div>
                        <div className="cart-body-container">
                            <h1>My Order</h1>
                            {this.state.cart && this.state.cart.length > 0 ? this.orderBody() :
                                <p className="delivery-address">No Products Added</p>
                            }
                        </div>
                        <div className="cart-total-container">
                            <hr className="grey-hr"/>
                            {this.state.address ?
                                <div className="row row-cols-2 nunito-sans-font font-weight-bold">
                                    <div className="col">
                                        <p>
                                            Delivery Charges
                                        </p>
                                    </div>
                                    <div className="col">
                                        <p>Rs. {this.state.deliveryLocation.amount}</p>
                                    </div>
                                </div>
                                : null
                            }
                            <div className="row row-cols-2 nunito-sans-font">
                                <div className="col">
                                    <h4>Total</h4>
                                </div>
                                <div className="col">
                                    <h4>Rs. {this.state.total}</h4>
                                </div>
                            </div>
                            <button className="btn red-button login-button">
                                <NavLink className="nav-link" to="/cart">Checkout</NavLink>
                            </button>
                        </div>
                    </div>
                );
            }
        } else {
            return null;
        }
    };

    orderBody = () => {
        let body = [];
        this.state.cart.forEach((food, index) => {
            if (this.props.isCartPage) {
                body.push(
                    <div className="cart-food-container" key={index}>
                        <div className="row row-cols-5 nunito-sans-font">
                            <div className="col">
                                <button className="btn remove-food-button font-weight-bold"
                                        onClick={() => this.updateCart(index, 'remove', 0)}>
                                    X
                                </button>
                            </div>
                            <div className="col desktop-food-image">
                                <img src={food.image_url} alt={food.name + '\'s image'}/>
                            </div>
                            <div className="col">
                                <div className="custom-number" key="quantity">
                                    <button className="btn"
                                            onClick={() => this.updateCart(index, 'update', -1)}>
                                        -
                                    </button>
                                    <input className="custom-number-input" id="quantity" min="1" name="quantity"
                                           value={food.selected_quantity} max={food.quantity} type="number"
                                           readOnly/>
                                    <button className="btn"
                                            onClick={() => this.updateCart(index, 'update', 1)}>
                                        +
                                    </button>
                                </div>
                            </div>
                            <div className="col">
                                <h6 className="capital-text font-weight-bold">{food.name}</h6>
                            </div>
                            <div className="col">
                                <h6 className="font-weight-bold">Rs. {food.price * food.selected_quantity}</h6>
                            </div>
                        </div>
                    </div>
                )
            } else {
                body.push(
                    <div className="cart-food-container" key={index}>
                        <div className="row row-cols-2 nunito-sans-font">
                            <div className="col">
                                <h6 className="capital-text font-weight-bold">{food.name}</h6>
                            </div>
                            <div className="col price-col">
                                <h6 className="font-weight-bold">Rs. {food.price * food.selected_quantity}</h6>
                            </div>
                        </div>
                        <div className="custom-number" key="quantity">
                            <button className="btn remove-food-button"
                                    onClick={() => this.updateCart(index, 'remove', 0)}>
                                X
                            </button>
                            <button className="btn"
                                    onClick={() => this.updateCart(index, 'update', -1)}>
                                -
                            </button>
                            <input className="custom-number-input" id="quantity" min="1" name="quantity"
                                   value={food.selected_quantity} max={food.remaining_quantity} type="number"
                                   readOnly/>
                            <button className="btn"
                                    onClick={() => this.updateCart(index, 'update', 1)}>
                                +
                            </button>
                        </div>
                    </div>
                )
            }
        });
        return body;
    }

    updateCart = async (index, action, updateBy) => {
        let cart = this.state.cart;
        if (action === 'remove') {
            let product = cart[index];
            cart.splice(index, 1);
            await getBuyGetOffers().then(response => {
                let buyGetOffers = response;
                const buyFoodItemGetOfferIndex = buyGetOffers.food.findIndex(function (item) {
                    return (item.buy_type_id === product.id);
                });
                if (buyFoodItemGetOfferIndex >= 0) {
                    let food = buyGetOffers.food[buyFoodItemGetOfferIndex].food,
                        cartOfferIndex = cart.findIndex(function (item) {
                            return (item.id === food.id && item.name === (food.name.includes(' - Free') ? food.name : food.name + ' - Free'));
                        });
                    if (cartOfferIndex >= 0) {
                        cart.splice(cartOfferIndex, 1);
                    }
                }

                const buySubFoodTypeGetOfferIndex = buyGetOffers.sub_food_types.findIndex(function (item) {
                    return (item.buy_type_id === product.sub_food_type_id);
                });
                if (buySubFoodTypeGetOfferIndex >= 0) {
                    let offer = buyGetOffers.sub_food_types[buySubFoodTypeGetOfferIndex],
                        food = buyGetOffers.sub_food_types[buySubFoodTypeGetOfferIndex].food,
                        cartOfferIndex = cart.findIndex(function (item) {
                            return (item.id === food.id && item.name === (food.name.includes(' - Free') ? food.name : food.name + ' - Free'));
                        }),
                        cartSubFoodTypes = cart.filter((item) => item.sub_food_type_id === product.sub_food_type_id && !item.name.includes(' - Free')),
                        productQuantity = 0;
                    if (cartOfferIndex >= 0) {
                        cartSubFoodTypes.forEach((item) => {
                            productQuantity += item.selected_quantity;
                        });
                        if (cartSubFoodTypes.length >= offer.buy_quantity || productQuantity >= offer.buy_quantity) {
                            cart[cartOfferIndex].selected_quantity = offer.get_quantity * Math.floor(productQuantity / offer.buy_quantity);
                            cart[cartOfferIndex].remaining_quantity = offer.get_quantity * Math.floor(productQuantity / offer.buy_quantity);
                        } else {
                            cart.splice(cartOfferIndex, 1);
                        }
                    }
                }
            });
        } else {
            cart[index].selected_quantity = cart[index].selected_quantity + updateBy <= cart[index].remaining_quantity ? cart[index].selected_quantity + updateBy > 0 ? cart[index].selected_quantity += updateBy : 1 : cart[index].remaining_quantity
            if (!cart[index].name.includes(' - Free')) {
                await getBuyGetOffers().then(response => {
                    let buyGetOffers = response;
                    const buyFoodItemGetOfferIndex = buyGetOffers.food.findIndex(function (item) {
                        return (item.buy_type_id === cart[index].id);
                    });
                    if (buyFoodItemGetOfferIndex >= 0) {
                        let offer = buyGetOffers.food[buyFoodItemGetOfferIndex],
                            food = buyGetOffers.food[buyFoodItemGetOfferIndex].food,
                            cartOfferIndex = cart.findIndex(function (item) {
                                return (item.id === food.id && item.name === (food.name.includes(' - Free') ? food.name : food.name + ' - Free'));
                            });
                        if (cart[index].selected_quantity >= offer.buy_quantity) {
                            if (cartOfferIndex >= 0) {
                                cart[cartOfferIndex].selected_quantity = offer.get_quantity * Math.floor(cart[index].selected_quantity / offer.buy_quantity);
                                cart[cartOfferIndex].remaining_quantity = offer.get_quantity * Math.floor(cart[index].selected_quantity / offer.buy_quantity);
                            } else {
                                food.name = food.name.includes(' - Free') ? food.name : food.name + ' - Free';
                                food.price = 0;
                                food.selected_quantity = offer.get_quantity * Math.floor(cart[index].selected_quantity / offer.buy_quantity);
                                food.remaining_quantity = offer.get_quantity * Math.floor(cart[index].selected_quantity / offer.buy_quantity);
                                food.type = "Food";
                                cart.push(food);
                            }
                        } else if (cartOfferIndex >= 0) {
                            cart.splice(cartOfferIndex, 1);
                        }
                    }

                    const buySubFoodTypeGetOfferIndex = buyGetOffers.sub_food_types.findIndex(function (item) {
                        return (item.buy_type_id === cart[index].sub_food_type_id);
                    });
                    if (buySubFoodTypeGetOfferIndex >= 0) {
                        let offer = buyGetOffers.sub_food_types[buySubFoodTypeGetOfferIndex],
                            food = buyGetOffers.sub_food_types[buySubFoodTypeGetOfferIndex].food,
                            cartOfferIndex = cart.findIndex(function (item) {
                                return (item.id === food.id && item.name === (food.name.includes(' - Free') ? food.name : food.name + ' - Free'));
                            }),
                            cartSubFoodTypes = cart.filter((item) => item.sub_food_type_id === cart[index].sub_food_type_id && !item.name.includes(' - Free')),
                            productQuantity = 0;
                        cartSubFoodTypes.forEach((item) => {
                            productQuantity += item.selected_quantity;
                        });
                        if (productQuantity >= offer.buy_quantity) {
                            if (cartOfferIndex >= 0) {
                                cart[cartOfferIndex].selected_quantity = offer.get_quantity * Math.floor(productQuantity / offer.buy_quantity);
                                cart[cartOfferIndex].remaining_quantity = offer.get_quantity * Math.floor(productQuantity / offer.buy_quantity);
                            } else {
                                food.name = food.name.includes(' - Free') ? food.name : food.name + ' - Free';
                                food.price = 0;
                                if (cartSubFoodTypes.length >= offer.buy_quantity) {
                                    food.selected_quantity = offer.get_quantity * Math.floor(cartSubFoodTypes.length / offer.buy_quantity);
                                    food.remaining_quantity = offer.get_quantity * Math.floor(cartSubFoodTypes.length / offer.buy_quantity);
                                } else {
                                    food.selected_quantity = offer.get_quantity * Math.floor(cart[index].selected_quantity / offer.buy_quantity);
                                    food.remaining_quantity = offer.get_quantity * Math.floor(cart[index].selected_quantity / offer.buy_quantity);
                                }
                                food.type = "Food";
                                cart.push(food);
                            }
                        } else if (cartOfferIndex >= 0) {
                            cart.splice(cartOfferIndex, 1);
                        }
                    }
                });
            }
        }
        updateCart(cart).then(() => {
            this.getCartDetails(cart);
            if (this.props.updateState) {
                this.props.updateState();
            }
        });
        this.props.updateState();
    }
}

export default withRouter(CartComponent);