import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {
    getAddress,
    getBranch,
    getCart,
    getNotes,
    getPromoCode,
    loadDeliveryLocationDetails,
    updateNotes,
    updatePromoCode
} from "../common/Common";
import {getRequest} from "../routes/Routes";
import '../assets/css/SummaryComponent.css';

class CartComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: null,
            cart: null,
            branch: null,
            promoCode: null,
            deliveryLocation: null,
            total: 0,
            subTotal: 0,
            promoTotal: 0,
            loading: false,
            error: false,
            errorMessage: null,
            notes: ""
        };
    }

    async componentDidMount() {
        getBranch().then(branch => {
            this.setState({branch: branch});
        }).catch(() => null);
        await getAddress().then(async address => {
            await loadDeliveryLocationDetails(address.deliveryLocation).then(deliveryLocation => {
                this.setState({address: address, deliveryLocation: deliveryLocation});
            }).catch(() => null);
        }).catch(() => null);
        getCart().then(cart => {
            this.getCartDetails(cart);
        }).catch(() => null);
        getNotes().then(notes => {
            this.setState({notes: notes});
        }).catch(() => null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.count !== prevProps.count) {
            getCart().then(cart => {
                this.getCartDetails(cart);
            }).catch(() => null);
        }
    }

    getCartDetails = async (cart) => {
        let {deliveryLocation, promoTotal, promoCode} = this.state;
        let total = 0,
            subTotal = 0;
        total += deliveryLocation ? deliveryLocation.amount : 0;
        if (cart) {
            cart.forEach((food) => {
                total += food.price * food.selected_quantity;
                subTotal += food.price * food.selected_quantity;
            });
        }
        await getPromoCode().then(responsePromoCode => {
            if (subTotal < responsePromoCode.limit) {
                this.removePromoCode();
                promoCode = null;
                promoTotal = 0;
            } else {
                promoCode = responsePromoCode;
                promoTotal = subTotal * (promoCode.percentage / 100);
            }
        }).catch(() => null);
        total -= promoTotal;
        this.setState({cart: cart, total: total, subTotal: subTotal, promoTotal: promoTotal, promoCode: promoCode});
    }

    render() {
        return (
            <div className="cart-container container summary-container">
                <h1>Summary</h1>
                {this.state.branch ?
                    <div className="mobile-summary-component">
                        {this.state.address ? [
                            <h6 key="heading" className="nunito-sans-font font-weight-bold">Delivery Address</h6>,
                            <div className="row row-cols-2 nunito-sans-font" key="address">
                                <div className="col">
                                    <p className="delivery-address">
                                        {this.state.address.address}
                                    </p>
                                </div>
                                <div className="col">
                                    <NavLink className="nav-link red-font" to="/address">
                                        Change Address
                                    </NavLink>
                                </div>
                            </div>
                        ] : [
                            <h6 key="heading">Takeout/Pickup Location:</h6>,
                            <p key="address" className="delivery-address">
                                {this.state.branch.name}
                            </p>
                        ]}
                    </div>
                    : null
                }
                <div className="summary-total-container">
                    <div className="row row-cols-2 nunito-sans-font font-weight-bold">
                        <div className="col">
                            <p>
                                Sub Total
                            </p>
                        </div>
                        <div className="col">
                            <p>Rs. {this.state.subTotal}</p>
                        </div>
                    </div>
                    {this.state.promoCode ?
                        <div className="row row-cols-2 nunito-sans-font font-weight-bold">
                            <div className="col">
                                <p>
                                    {this.state.promoCode.name}
                                </p>
                            </div>
                            <div className="col">
                                <p>(Rs. {this.state.promoTotal})</p>
                            </div>
                        </div>
                        : null
                    }
                    {this.state.address ?
                        <div className="row row-cols-2 nunito-sans-font font-weight-bold">
                            <div className="col">
                                <p>
                                    Delivery Charges
                                </p>
                            </div>
                            <div className="col">
                                <p>Rs. {this.state.deliveryLocation.amount}</p>
                            </div>
                        </div>
                        : null
                    }
                    <div className="row row-cols-2 nunito-sans-font summary-total-row">
                        <div className="col">
                            <h1 className="font-weight-bold">Total</h1>
                        </div>
                        <div className="col">
                            <h1 className="font-weight-bold">Rs. {this.state.total}</h1>
                        </div>
                    </div>
                    {this.props.isCartPage ? [
                        <form onSubmit={this.addPromoCode} key="promoCode">
                            <div className="input-group">
                                <input type="text"
                                       className="form-control black-form inline-button-input nunito-sans-font"
                                       id="promoCode" placeholder="Type Code here" required/>
                                <div className="input-group-append">
                                    <button className="btn black-button" type="submit">
                                        {this.state.loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Add Promo Code
                                    </button>
                                </div>
                            </div>
                            {this.state.error &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMessage}
                            </div>}
                            {this.state.promoCode &&
                            <p className="red-font remove-promo-code-button" onClick={(() => this.removePromoCode())}>
                                Remove Promo Code
                            </p>
                            }
                        </form>,
                        <div className="notes-component">
                            <textarea className="form-control black-form custom-textarea" placeholder='Notes' id="notes"
                                      onChange={this.handleNotesChange} value={this.state.notes} maxLength="60"/>
                            <small id="notes" className="form-text text-muted">
                                Any notes for the order
                            </small>
                        </div>,
                        <button className="btn red-button login-button" key="checkoutButton">
                            <NavLink className="nav-link" to="/checkout">Continue to Checkout</NavLink>
                        </button>
                    ] : null}
                </div>
            </div>
        );
    }

    addPromoCode = (event) => {
        this.setState({loading: true});
        event.preventDefault();
        getRequest('/api/promo-codes/code/' + event.target.promoCode.value + '/branch/' + this.state.branch.id + '/sub-total/' + this.state.subTotal).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({error: true, errorMessage: response.data.message});
                } else {
                    if (response.data.promoCode) {
                        updatePromoCode(response.data.promoCode).then(() => null);
                    } else {
                        this.setState({error: true, errorMessage: 'No such Promo available!'});
                    }
                }
            } else {
                this.setState({error: true, errorMessage: 'An error occurred!'});
            }
            this.setState({loading: false});
        });
    }

    removePromoCode = () => {
        let {total, promoTotal} = this.state;
        total += promoTotal;
        updatePromoCode(null).then(() => null);
        this.setState({promoCode: null, total: total, error: false});
    }

    handleNotesChange = (event) => {
        updateNotes(event.target.value).then(() => null);
        this.setState({notes: event.target.value});
    }
}

export default CartComponent;