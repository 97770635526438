import React, {Component} from 'react';
import {isTablet, isMobile} from 'react-device-detect';
import {addFoodToCart} from "../common/Common";
import '../assets/css/FoodList.css';

class FoodList extends Component {
    constructor(props) {
        super(props);
        this.state = {food: this.props.food, branch: this.props.branch}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.food !== prevProps.food) {
            this.setState({food: this.props.food});
        }

        if (this.props.branch !== prevProps.branch) {
            this.setState({branch: this.props.branch});
        }
    }

    getFoodCode(food, index) {
        let branchOpen = true,
            currentDate = new Date(),
            foodOpen = this.getTime(new Date(currentDate.getTime()), food.open + ':00') <= currentDate && this.getTime(new Date(currentDate.getTime()), food.close + ':00') >= currentDate;
        if (this.state.branch) {
            let {branch} = this.state,
                branchOpenDate = this.getTime(new Date(currentDate.getTime()), branch.open + ':00'),
                branchCloseDate = this.getTime(new Date(currentDate.getTime()), branch.close + ':00');
            branchOpen = branchOpenDate <= currentDate && branchCloseDate >= currentDate;
        }

        return (
            <div key={index}
                 className={(this.props.offers ? "col-4" : "col-3") + " food-container nunito-sans-font font-weight-bold"}>
                {isMobile && !isTablet && !this.props.offers ?
                    <div className="d-flex bd-highlight w-100 mobile-food-component">
                        <div className="p-2 flex-fill bd-highlight mobile-food-image-component">
                            <div className="food-image-container">
                                <img src={food.image_url} alt={food.name + '\'s image'}/>
                            </div>
                        </div>
                        <div className="p-2 flex-fill bd-highlight food-details">
                            <div className="capital-text">
                                {food.name}
                            </div>
                            <div className="quantity-container">
                                <div className="custom-number">
                                    <button className="btn"
                                            onClick={(event => this.updateSelectedQuantity(-1, index))}>
                                        -
                                    </button>
                                    <input className="custom-number-input" id="quantity" min="1" name="quantity"
                                           value={food.selected_quantity} max={food.remaining_quantity}
                                           type="number"
                                           readOnly/>
                                    <button className="btn"
                                            onClick={(event => this.updateSelectedQuantity(1, index))}>
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 flex-fill bd-highlight">
                            <div className="food-price">
                                Rs. {food.price * food.selected_quantity}
                            </div>
                            <div className="food-price">
                                {food.remaining_quantity > 0 ?
                                    branchOpen ?
                                        foodOpen ?
                                            <button className="btn white-button add-to-cart-button"
                                                    onClick={() => {
                                                        addFoodToCart(food).then(() => this.props.updateState());
                                                    }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17.852"
                                                     height="21.157"
                                                     viewBox="0 0 17.852 21.157">
                                                    <path className="a"
                                                          d="M57.852,18.678a.826.826,0,0,1-.826.826H56.2v.826a.826.826,0,0,1-1.653,0V19.5h-.826a.826.826,0,1,1,0-1.653h.826v-.826a.826.826,0,0,1,1.653,0v.826h.826A.826.826,0,0,1,57.852,18.678Zm0-12.893v7.934a.826.826,0,1,1-1.653,0V6.612H54.546V9.091a.826.826,0,0,1-1.653,0V6.612H44.959V9.091a.826.826,0,1,1-1.653,0V6.612H41.653V19.5h8.76a.826.826,0,1,1,0,1.653H40.826A.826.826,0,0,1,40,20.331V5.785a.826.826,0,0,1,.826-.826h2.518a5.62,5.62,0,0,1,11.162,0h2.518A.826.826,0,0,1,57.852,5.785Zm-5.014-.826a3.967,3.967,0,0,0-7.823,0Z"
                                                          transform="translate(-40)"/>
                                                </svg>
                                                Add to Cart
                                            </button>
                                            : <button className="btn white-button add-to-cart-button" disabled>
                                                Not Available
                                            </button>
                                        : <button className="btn white-button add-to-cart-button" disabled>
                                            Branch Closed
                                        </button>
                                    : <button className="btn white-button add-to-cart-button" disabled>
                                        Out of Stock
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                    : [
                        <div className="food-detail-container desktop-food-detail-container" key="foodDetails">
                            <div className="d-flex bd-highlight food-details">
                                {isMobile && !isTablet ?
                                    <div className="p-2 bd-highlight food-image-container mobile-offer" key="foodImage">
                                        <img src={food.image_url} alt={food.name + '\'s image'}/>
                                    </div>
                                    : [
                                        <div className="p-2 bd-highlight capital-text food-name" key="foodName">
                                            {food.name}
                                            <div className="desktop-food-price">
                                                Rs. {food.price * food.selected_quantity}
                                            </div>
                                        </div>,
                                        <div className="p-2 bd-highlight food-image-container" key="foodImage">
                                            <img src={food.image_url} alt={food.name + '\'s image'}/>
                                        </div>
                                    ]
                                }
                            </div>
                        </div>,
                        !this.props.offers ?
                            <div className="food-detail-container add-to-cart-form" key="form">
                                <div className="d-flex bd-highlight food-details">
                                    <div className="p-2 bd-highlight quantity-container">
                                        <div className="custom-number">
                                            <button className="btn"
                                                    onClick={(event => this.updateSelectedQuantity(-1, index))}>
                                                -
                                            </button>
                                            <input className="custom-number-input" id="quantity" min="1" name="quantity"
                                                   value={food.selected_quantity} max={food.remaining_quantity}
                                                   type="number"
                                                   readOnly/>
                                            <button className="btn"
                                                    onClick={(event => this.updateSelectedQuantity(1, index))}>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <div className="p-2 bd-highlight food-price">
                                        {food.remaining_quantity > 0 ?
                                            branchOpen ?
                                                foodOpen ?
                                                    <button className="btn white-button add-to-cart-button"
                                                            onClick={() => {
                                                                addFoodToCart(food).then(() => this.props.updateState());
                                                            }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.852"
                                                             height="21.157"
                                                             viewBox="0 0 17.852 21.157">
                                                            <path className="a"
                                                                  d="M57.852,18.678a.826.826,0,0,1-.826.826H56.2v.826a.826.826,0,0,1-1.653,0V19.5h-.826a.826.826,0,1,1,0-1.653h.826v-.826a.826.826,0,0,1,1.653,0v.826h.826A.826.826,0,0,1,57.852,18.678Zm0-12.893v7.934a.826.826,0,1,1-1.653,0V6.612H54.546V9.091a.826.826,0,0,1-1.653,0V6.612H44.959V9.091a.826.826,0,1,1-1.653,0V6.612H41.653V19.5h8.76a.826.826,0,1,1,0,1.653H40.826A.826.826,0,0,1,40,20.331V5.785a.826.826,0,0,1,.826-.826h2.518a5.62,5.62,0,0,1,11.162,0h2.518A.826.826,0,0,1,57.852,5.785Zm-5.014-.826a3.967,3.967,0,0,0-7.823,0Z"
                                                                  transform="translate(-40)"/>
                                                        </svg>
                                                        Add to Cart
                                                    </button>
                                                    : <span className="d-inline-block" tabIndex="0"
                                                            data-bs-toggle="tooltip"
                                                            title={"Food is only served between " + food.open + " - " + food.close}>
                                                    <button className="btn white-button add-to-cart-button" disabled>
                                                        Not Available
                                                    </button>
                                                </span>
                                                : <button className="btn white-button add-to-cart-button" disabled>
                                                    Branch Closed
                                                </button>
                                            : <button className="btn white-button add-to-cart-button" disabled>
                                                Out of Stock
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            : null
                    ]}
            </div>
        );
    }

    render() {
        let foodList = [],
            array = this.props.offers ? this.props.offers : this.state.food;

        array.forEach((element, index) => {
            element.selected_quantity = element.selected_quantity ? element.selected_quantity : 1;
            foodList.push(this.getFoodCode(element, index))
        });

        return (
            <div className="row">
                {foodList}
            </div>
        );
    };

    updateSelectedQuantity = (updateBy, index) => {
        let {food} = this.state;
        food[index].selected_quantity = food[index].selected_quantity + updateBy <= food[index].remaining_quantity ? food[index].selected_quantity + updateBy > 0 ? food[index].selected_quantity += updateBy : 0 : food[index].remaining_quantity
        this.setState({food: food});
    }

    getTime = (date, string) => {
        date.setHours(string.split(":")[0]);
        date.setMinutes(string.split(":")[1]);
        date.setSeconds(string.split(":")[2]);
        return date;
    }
}

export default FoodList;