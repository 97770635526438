import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {getBranch} from "../common/Common";
import '../assets/css/Footer.css';

import facebook from '../assets/images/facebook.png';
import twitter from '../assets/images/twitter.png';
import instagram from '../assets/images/instagram.png';
import logo from '../assets/images/logo.png';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {branch: null};
    }

    componentDidMount() {
        getBranch().then(branch => {
            this.setState({branch: branch});
        })
    }

    render() {
        return (
            <footer className="container segoe-ui-font">
                <div className="d-flex bd-highlight w-100">
                    <div className="p-2 flex-fill bd-highlight">
                        <div className="d-flex bd-highlight w-100 footer-container">
                            <div className="p-2 flex-fill bd-highlight">
                                <img src={logo} alt="Logo"/>
                            </div>
                            <div className="p-2 flex-fill bd-highlight">
                                <div className="font-weight-bold">About</div>
                                <div className="footer-details">
                                    <NavLink className="nav-link" to="/about-us">About Us</NavLink>
                                    <NavLink className="nav-link" to="/feedback">Feedback</NavLink>
                                    <NavLink className="nav-link" to="/">Branches</NavLink>
                                </div>
                            </div>
                            <div className="p-2 flex-fill bd-highlight">
                                <div className="font-weight-bold">Policy</div>
                                <div className="footer-details">
                                    <NavLink className="nav-link" to="/terms-and-conditions">Terms &
                                        Conditions</NavLink>
                                    <NavLink className="nav-link" to="/privacy-policy">Privacy Policy</NavLink>
                                </div>
                            </div>
                            <div className="p-2 flex-fill bd-highlight">
                                <div className="font-weight-bold">Contact</div>
                                <div className="footer-details">
                                    <div>management@thesignature.lk</div>
                                    {this.state.branch ?
                                        <div>{this.state.branch.phone}</div>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className="p-2 flex-fill bd-highlight">
                                <div className="font-weight-bold">Social</div>
                                <div className="footer-details">
                                    <NavLink className="social-icon"
                                             to={{pathname: "https://www.facebook.com/BurgerHutSriLanka/"}}
                                             target="_blank">
                                        <img src={facebook} alt="Facebook"/>
                                    </NavLink>
                                    <NavLink className="social-icon"
                                             to={{pathname: "https://www.twitter.com/burgerhutsrilanka/"}}
                                             target="_blank">
                                        <img src={twitter} alt="Twitter"/>
                                    </NavLink>
                                    <NavLink className="social-icon"
                                             to={{pathname: "https://www.instagram.com/burgerhutsrilanka/"}}
                                             target="_blank">
                                        <img src={instagram} alt="Instagram"/>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-rights">
                    ® {new Date().getFullYear()} Burger Hut. All rights reserved.
                </div>
            </footer>
        );
    }
}

export default Footer;