import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {deleteUser, getUser} from "../auth/Auth";
import {getBranch, getCart, loadBranches, setBranch, updateBranch} from "../common/Common";
import logo from '../assets/images/logo.png';
import cart from '../assets/images/cart.png';
import '../assets/css/Header.css';

import phone from '../assets/images/phone.svg';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {user: null, branches: [], branch: null, cart: null};
    }

    componentDidMount() {
        getUser().then(data => {
            this.setState({user: data});
        }).catch(() => null);
        loadBranches().then(data => {
            if (data.length > 0) {
                setBranch(data[0]).then(() => {
                    return null
                });
            }
            this.setState({branches: data});
        }).catch(() => null);
        getBranch().then(branch => {
            this.setState({branch: branch});
        })
        getCart().then(cart => {
            this.setState({cart: cart});
        }).catch(() => null);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.count !== prevProps.count) {
            getCart().then(cart => {
                this.setState({cart: cart});
            }).catch(() => null);
        }
    }

    render() {
        return (
            <header className="fixed-top navbar-header">
                <nav className="navbar navbar-expand-lg navbar-light container mobile-navbar">
                    <NavLink className="navbar-brand" to="/" key="logo">
                        <img src={logo} alt="Logo"/>
                    </NavLink>
                    <NavLink className="nav-link cart-link" to="/cart">
                        <img src={cart} alt="Cart"/>
                        {this.state.cart && this.state.cart.length > 0 ?
                            <span className="badge">{this.state.cart.length}</span>
                            : null}
                    </NavLink>
                    {this.state.branch ?
                        <span className="nav-item p-2 flex-fill bd-highlight branch-phone-number"
                              key="partners">
                                    <span className="nav-link" onClick={this.handleCall}>
                                        <img src={phone} alt="Phone icon"/>
                                        {this.state.branch.phone}
                                    </span>
                            </span>
                        : null}
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation" key="toggle-button">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" key="nav-list">
                        <ul className="navbar-nav mr-auto"/>
                        <ul className="navbar-nav navbar-links d-flex bd-highlight">
                            <li className="nav-item p-2 flex-fill bd-highlight" key="menu">
                                <NavLink className="nav-link" to="/menu" data-toggle="collapse"
                                         data-target="#navbarSupportedContent">Menu</NavLink>
                            </li>
                            <li className="nav-item p-2 flex-fill bd-highlight" key="offers">
                                <NavLink className="nav-link" to="/offers" data-toggle="collapse"
                                         data-target="#navbarSupportedContent">Offers</NavLink>
                            </li>
                            <li className="nav-item dropdown p-2 flex-fill bd-highlight" key="otherDropdown">
                                <NavLink className="nav-link dropdown-toggle" to="#" id="otherDropdown"
                                         role="button" data-toggle="dropdown" aria-haspopup="true"
                                         aria-expanded="false">
                                    Other
                                </NavLink>
                                <div className="dropdown-menu" aria-labelledby="otherDropdown">
                                    <NavLink className="nav-link" to="/about-us" key="about-us"
                                             data-toggle="collapse" data-target="#navbarSupportedContent">
                                        About Us
                                    </NavLink>
                                    <hr/>
                                    <NavLink className="nav-link" to="/privacy-policy" key="privacy-policy"
                                             data-toggle="collapse"
                                             data-target="#navbarSupportedContent">
                                        Privacy Policy
                                    </NavLink>
                                    <NavLink className="nav-link" to="/terms-and-conditions" key="terms-and-conditions"
                                             data-toggle="collapse"
                                             data-target="#navbarSupportedContent">
                                        Terms & Conditions
                                    </NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown p-2 flex-fill bd-highlight" key="branchDropdown">
                                <NavLink className="nav-link dropdown-toggle red-font capital-text" to="#"
                                         id="branchDropdown"
                                         role="button" data-toggle="dropdown" aria-haspopup="true"
                                         aria-expanded="false">
                                    {this.state.branch ? this.state.branch.name : 'Select Branch'}
                                </NavLink>
                                <div className="dropdown-menu" aria-labelledby="branchDropdown">
                                    <span className="nav-link" key="branch">Select Branch</span>
                                    <hr/>
                                    {this.branchDropdown()}
                                </div>
                            </li>
                            {this.state.user ?
                                <li className="nav-item dropdown p-2 flex-fill bd-highlight" key="user">
                                    <NavLink className="nav-link dropdown-toggle" to="#" id="userDropdown"
                                             role="button" data-toggle="dropdown" aria-haspopup="true"
                                             aria-expanded="false">
                                        Hi, <span className="capital-text">
                                        {this.state.user.name}</span>
                                    </NavLink>
                                    <div className="dropdown-menu" aria-labelledby="userDropdown">
                                        <span className="nav-link" key="user-menu">User Menu</span>
                                        <hr/>
                                        <NavLink className="nav-link" to="/portal" key="profile"
                                                 data-toggle="collapse"
                                                 data-target="#navbarSupportedContent">Portal</NavLink>
                                        {this.state.user.role_id === 2 || this.state.user.role_id === 3 || this.state.user.role_id === 4 ?
                                            <NavLink className="nav-link" to="/admin" key="users"
                                                     data-toggle="collapse" data-target="#navbarSupportedContent">
                                                Admin Panel
                                            </NavLink>
                                            : null
                                        }
                                        {this.state.user.role_id === 3 ?
                                            <NavLink className="nav-link" to="/finance" key="finance"
                                                     data-toggle="collapse" data-target="#navbarSupportedContent">
                                                Finance Portal
                                            </NavLink>
                                            : null
                                        }
                                        <NavLink className="nav-link" to="/login" onClick={this.logout}
                                                 key="logout" data-toggle="collapse"
                                                 data-target="#navbarSupportedContent">
                                            Sign out of <span
                                            className="capital-text font-weight-bold">{this.state.user.name}</span>
                                        </NavLink>
                                    </div>
                                </li>
                                : null
                            }
                        </ul>
                    </div>
                </nav>
                <nav className="navbar navbar-expand-lg navbar-light container desktop-navbar">
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" key="nav-list">
                        <ul className="navbar-nav navbar-links d-flex bd-highlight">
                            <li className="nav-item p-2 flex-fill bd-highlight" key="menu">
                                <NavLink className="nav-link" to="/menu">Menu</NavLink>
                            </li>
                            <li className="nav-item p-2 flex-fill bd-highlight" key="offers">
                                <NavLink className="nav-link" to="/offers">Offers</NavLink>
                            </li>
                            <li className="nav-item dropdown p-2 flex-fill bd-highlight" key="otherDropdown">
                                <NavLink className="nav-link dropdown-toggle" to="#" id="otherDropdown"
                                         role="button" data-toggle="dropdown" aria-haspopup="true"
                                         aria-expanded="false">
                                    Other
                                </NavLink>
                                <div className="dropdown-menu" aria-labelledby="otherDropdown">
                                    <NavLink className="nav-link" to="/about-us" key="about-us">About Us</NavLink>
                                    <hr/>
                                    <NavLink className="nav-link" to="/privacy-policy" key="privacy-policy">
                                        Privacy Policy
                                    </NavLink>
                                    <NavLink className="nav-link" to="/terms-and-conditions" key="terms-and-conditions">
                                        Terms & Conditions
                                    </NavLink>
                                </div>
                            </li>
                            <li className="nav-item dropdown p-2 flex-fill bd-highlight" key="branchDropdown">
                                <NavLink className="nav-link dropdown-toggle red-font capital-text" to="#"
                                         id="branchDropdown"
                                         role="button" data-toggle="dropdown" aria-haspopup="true"
                                         aria-expanded="false">
                                    {this.state.branch ? this.state.branch.name : 'Select Branch'}
                                </NavLink>
                                <div className="dropdown-menu" aria-labelledby="branchDropdown">
                                    {this.branchDropdown()}
                                </div>
                            </li>
                        </ul>
                        <ul className="nav navbar-brand-container">
                            <li className="nav-item">
                                <NavLink className="navbar-brand" to="/" key="logo">
                                    <img src={logo} alt="Logo"/>
                                </NavLink>
                            </li>
                        </ul>
                        <ul className="navbar-nav mr-auto"/>
                        <ul className="nav justify-content-end">
                            {this.state.user ?
                                <li className="nav-item dropdown"
                                    key="user">
                                    <NavLink className="nav-link dropdown-toggle" to="#" id="userDropdown"
                                             role="button" data-toggle="dropdown" aria-haspopup="true"
                                             aria-expanded="false">
                                        Hi, <span className="capital-text">
                                        {this.state.user.name}</span>
                                    </NavLink>
                                    <div className="dropdown-menu" aria-labelledby="userDropdown">
                                        <NavLink className="nav-link" to="/portal" key="profile">Portal</NavLink>
                                        {this.state.user.role_id === 2 || this.state.user.role_id === 3 || this.state.user.role_id === 4 ?
                                            <NavLink className="nav-link" to="/admin" key="users">
                                                Admin Panel
                                            </NavLink>
                                            : null
                                        }
                                        {this.state.user.role_id === 3 ?
                                            <NavLink className="nav-link" to="/finance" key="finance">
                                                Finance Portal
                                            </NavLink>
                                            : null
                                        }
                                        <NavLink className="nav-link" to="/login" onClick={this.logout}
                                                 key="logout">
                                            Sign out of <span
                                            className="capital-text font-weight-bold">{this.state.user.name}</span>
                                        </NavLink>
                                    </div>
                                </li>
                                : null
                            }
                            <li className={"nav-item cart-link-container" + (this.state.user ? ' logged-in-cart-link-container' : '') + (this.state.branch ? '' : ' no-branch-cart-link-container')}
                                key="cart">
                                <NavLink className="nav-link cart-link" to="/cart">
                                    <img src={cart} alt="Cart"/>
                                    {this.state.cart && this.state.cart.length > 0 ?
                                        <span className="badge">{this.state.cart.length}</span>
                                        : null}
                                </NavLink>
                            </li>
                            {this.state.branch ?
                                <li className="nav-item"
                                    key="partners">
                                    <span className="nav-link">
                                        <img src={phone} alt="Phone icon" className="phone-icon"/>
                                        {this.state.branch.phone}
                                    </span>
                                </li>
                                : null}
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }

    logout = () => {
        deleteUser().then(() =>
            this.setState({user: null})
        ).catch(() => null);
    };

    branchDropdown = () => {
        let body = [];
        this.state.branches.forEach((branch, index) => {
            body.push(
                <NavLink className="nav-link capital-text" to="/#" key={index}
                         onClick={(event) => updateBranch(branch, this.props.history)}>
                    {branch.name}
                </NavLink>
            )
        });
        return body;
    }

    handleCall = () => {
        window.location.href = 'tel:' + this.state.branch.phone;
    }
}

export default withRouter(Header);