import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {combineReducers, createStore} from 'redux';
import {sessionReducer, sessionService} from 'redux-react-session';
import {positions, Provider} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import {getUser} from "./auth/Auth";
import ScrollToTop from './components/ScrollToTop';
import Header from "./views/Header";
import Home from "./views/Home";
import AboutUs from "./views/AboutUs";
import Address from "./views/Address";
import Menu from "./views/Menu";
import Cart from "./views/Cart";
import Checkout from "./views/Checkout";
import SummaryComponent from "./components/SummaryComponent";
import Order from "./views/Order";
import Admin from "./views/Admin";
import Portal from "./views/Portal";
import Finance from "./views/Finance";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsAndConditions from "./views/TermsAndConditions";
import './App.css';

class App extends Component {
    constructor(props) {
        super(props);
        const reducer = combineReducers({
            session: sessionReducer
        });
        const store = createStore(reducer);
        sessionService.initSessionService(store);

        const options = {
            timeout: 5000,
            position: positions.MIDDLE_RIGHT
        };

        this.state = {options: options, user: undefined, count: 0};
    }

    componentDidMount() {
        getUser().then((data) => {
            this.setState({user: data});
        }).catch(() => {
            this.setState({user: null});
        });
    }

    updateState = () => {
        let {count} = this.state;
        this.setState({count: ++count});
    }

    render() {
        const {options, user} = this.state;
        if (user !== undefined) {
            return (
                <Router>
                    <ScrollToTop>
                        <div id="header">
                            <Header count={this.state.count}/>
                        </div>
                        <div id="body">
                            <Provider template={AlertTemplate} {...options} id="body">
                                <Switch>
                                    <Route exact path="/">
                                        <Home isLoginComponent={false}/>
                                    </Route>
                                    <Route exact path="/login">
                                        <Home isLoginComponent={true}/>
                                    </Route>
                                    <Route exact path="/about-us">
                                        <AboutUs/>
                                    </Route>
                                    <Route exact path="/privacy-policy">
                                        <PrivacyPolicy/>
                                    </Route>
                                    <Route exact path="/terms-and-conditions">
                                        <TermsAndConditions/>
                                    </Route>
                                    <Route exact path="/address">
                                        <Address/>
                                    </Route>
                                    <Route exact path="/menu">
                                        <Menu isFoodComponent={true} updateState={this.updateState}/>
                                    </Route>
                                    <Route exact path="/offers">
                                        <Menu isFoodComponent={false} updateState={this.updateState}/>
                                    </Route>
                                    <Route exact path="/cart">
                                        <Cart updateState={this.updateState}/>
                                    </Route>
                                    <Route exact path="/checkout">
                                        <Checkout updateState={this.updateState}/>
                                    </Route>
                                    <Route exact path="/summary">
                                        <SummaryComponent isCartPage={true}/>
                                    </Route>
                                    <Route exact path="/order/:orderNumber">
                                        <Order/>
                                    </Route>
                                    <Route exact path="/portal">
                                        {user ? <Portal/> : <Redirect to="/login"/>}
                                    </Route>
                                    <Route exact path="/admin">
                                        {user && (user.role_id === 2 || user.role_id === 3 || user.role_id === 4) ?
                                            <Admin/> :
                                            <Redirect to="/login"/>}
                                    </Route>
                                    <Route exact path="/finance">
                                        {user && (user.role_id === 3) ? <Finance/> :
                                            <Redirect to="/login"/>}
                                    </Route>
                                    <Route path="*">
                                        <Redirect to="/"/>
                                    </Route>
                                </Switch>
                            </Provider>
                        </div>
                    </ScrollToTop>
                </Router>
            );
        } else {
            return null;
        }
    }
}

export default App;
