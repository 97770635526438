import React, {Component} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import {isMobile, isTablet} from 'react-device-detect';
import SummaryComponent from "../components/SummaryComponent";
import {postRequest} from "../routes/Routes";
import {
    getAddress,
    getBranch,
    getCart,
    getNotes,
    getPromoCode,
    loadDeliveryLocationDetails,
    updateCart,
    updateNotes,
    updatePromoCode
} from "../common/Common";
import '../assets/css/Cart.css';
import '../assets/css/Checkout.css';

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: null,
            cart: null,
            branch: null,
            promoCode: null,
            deliveryLocation: null,
            total: 0,
            subTotal: 0,
            promoTotal: 0,
            error: false,
            errorMessage: null,
            loading: false,
            orderSuccessful: false,
            otpSuccessful: false,
            otpError: false,
            otpMessage: null,
            order: null,
            notes: "",
            payOnDelivery: false
        };
    }

    componentDidMount() {
        getBranch().then(branch => {
            this.setState({branch: branch});
        }).catch(() => null);
        getAddress().then(address => {
            loadDeliveryLocationDetails(address.deliveryLocation).then(deliveryLocation => {
                let total = this.state.total;
                total += deliveryLocation.amount;
                this.setState({address: address, deliveryLocation: deliveryLocation, total: total});
            }).catch(() => null);
        }).catch(() => null);
        getCart().then(async cart => {
            let {total, subTotal, promoTotal, promoCode} = this.state;
            cart.forEach((food) => {
                total += food.price * food.selected_quantity;
                subTotal += food.price * food.selected_quantity;
            });
            await getPromoCode().then(responsePromoCode => {
                promoCode = responsePromoCode;
                promoTotal = subTotal * (promoCode.percentage / 100);
            }).catch(() => null);
            total -= promoTotal;
            this.setState({cart: cart, total: total, subTotal: subTotal, promoTotal: promoTotal, promoCode: promoCode});
        }).catch(() => null);
        getNotes().then(notes => {
            this.setState({notes: notes});
        }).catch(() => null);
    }

    render() {
        return (
            <div className="container">
                <div className="d-flex bd-highlight w-100">
                    <div className="p-2 flex-fill bd-highlight cart-component">
                        <form className="cart-container checkout-form" onSubmit={this.addOrder}>
                            <h1>Recipient Details</h1>
                            <div className="row row-cols-2 nunito-sans-font">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="firstName">First name*</label>
                                        <input type="text"
                                               className="form-control black-form nunito-sans-font"
                                               id="firstName" placeholder="First Name" required/>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="lastName">Last name*</label>
                                        <input type="text"
                                               className="form-control black-form nunito-sans-font"
                                               id="lastName" placeholder="Last Name" required/>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-cols-2 nunito-sans-font">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="phone">Contact Number*</label>
                                        <input type="tel" className="form-control black-form nunito-sans-font"
                                               placeholder='Contact Number' id="phone" pattern="[0-9]{10}" required/>
                                        <small id="phone" className="form-text text-muted">
                                            Please follow the pattern (0771234567)
                                        </small>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="email">Email Address*</label>
                                        <input type="email"
                                               className="form-control black-form nunito-sans-font"
                                               id="email" placeholder="Email Address" required/>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-detail-container">
                                <h1>Order/Payment Details</h1>
                                <div className="form-group">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" id="payOnline" name="paymentMethod" value="Pay Online"
                                               className="custom-control-input" defaultChecked
                                               onChange={() => this.handleOnChange(false)}/>
                                        <label className="custom-control-label" htmlFor="payOnline">Pay Online</label>
                                    </div>
                                    {this.state.address ?
                                        <div className="custom-control custom-radio">
                                            <input type="radio" id="payOnDelivery" name="paymentMethod"
                                                   value="Pay On Delivery" className="custom-control-input"
                                                   disabled={this.state.total > 3500}
                                                   onChange={() => this.handleOnChange(true)}/>
                                            <label className="custom-control-label" htmlFor="payOnDelivery">
                                                Pay on Delivery
                                            </label>
                                            {this.state.total > 3500 ?
                                                <small id="phone" className="form-text text-muted nunito-sans-font">
                                                    Minimum total to enable option is Rs. 3,500
                                                </small>
                                                : null
                                            }
                                        </div>
                                        : <div className="custom-control custom-radio">
                                            <input type="radio" id="payOnPickup" name="paymentMethod"
                                                   value="Pay On Pickup" className="custom-control-input"
                                                   disabled={this.state.total > 3500}/>
                                            <label className="custom-control-label" htmlFor="payOnPickup">
                                                Pay on Pickup
                                            </label>
                                            {this.state.total > 3500 ?
                                                <small id="phone" className="form-text text-muted nunito-sans-font">
                                                    Minimum total to enable option is Rs. 3,500
                                                </small>
                                                : null
                                            }
                                        </div>
                                    }
                                </div>
                                {this.state.payOnDelivery ? [
                                    <br key="br"/>,
                                    <div className="form-group" key="radio">
                                        <div className="custom-control custom-radio">
                                            <input type="radio" id="payByCash" name="deliveryPaymentMethod"
                                                   value="Pay by Cash" className="custom-control-input" defaultChecked/>
                                            <label className="custom-control-label" htmlFor="payByCash">Pay by
                                                Cash</label>
                                        </div>
                                        <div className="custom-control custom-radio">
                                            <input type="radio" id="payByCard" name="deliveryPaymentMethod"
                                                   value="Pay by Card" className="custom-control-input"/>
                                            <label className="custom-control-label" htmlFor="payByCard">Pay by
                                                Card</label>
                                        </div>
                                    </div>
                                ] : null}
                                <br/>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="termsAndAgreement"
                                           required/>
                                    <label className="custom-control-label" htmlFor="termsAndAgreement">
                                        I have read & agreed to the &nbsp;
                                        <NavLink className="red-font" to="/terms-and-conditions">
                                            Terms & Conditions
                                        </NavLink>
                                    </label>
                                </div>
                            </div>
                            {this.state.error &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMessage}
                            </div>}
                            <button className="btn green-button" type="submit"
                                    disabled={this.state.loading || (!this.state.cart || (this.state.cart && this.state.cart.length === 0))}>
                                {this.state.loading ?
                                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                          aria-hidden="true"/>
                                    : null
                                }
                                Confirm & Pay
                            </button>
                        </form>
                    </div>
                    <div className="p-2 flex-fill bd-highlight desktop-summary-component">
                        <SummaryComponent isCartPage={false}/>
                    </div>
                </div>
                <div className={"modal fade" + (this.state.orderSuccessful ? ' show-modal' : '')} tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                {this.state.order && this.state.order.payment_type === 'Pay Online' ?
                                    <form action="https://www.paystage.com/AccosaPG/verify.jsp"
                                          name="ipgForm" onSubmit={this.submitIPGForm} method="post">
                                        <input hidden name="pg_instance_id" value={this.state.order.instanceId}
                                               readOnly/>
                                        <input hidden name="merchant_id" value={this.state.order.merchantId} readOnly/>
                                        <input hidden name="perform" value="initiatePaymentCapture#sale" readOnly/>
                                        <input hidden name="currency_code" value="144" readOnly/>
                                        <input hidden name="amount" value={this.state.order.amount} readOnly/>
                                        <input hidden name="merchant_reference_no" value={this.state.order.reference}
                                               readOnly/>
                                        <input hidden name="order_desc" value={this.state.order.orderDescription}
                                               readOnly/>
                                        <input hidden name="message_hash" value={this.state.order.messageHash}
                                               readOnly/>
                                        <input hidden name="merchant_response_url" value={this.state.order.responseUrl}
                                               readOnly/>
                                        <button className="btn red-button login-button" type="submit"
                                                style={{marginBottom: 0}}>
                                            Continue to pay
                                        </button>
                                    </form>
                                    : [
                                        <h1>Thank you for Ordering!</h1>,
                                        <p>
                                            Kindly check your Email for the Order receipt, or view the receipt &nbsp;
                                            <NavLink className="red-font"
                                                     to={"/order/" + (this.state.order ? Buffer.from(String(this.state.order.reference)).toString('base64') : '')}>
                                                here.
                                            </NavLink>
                                        </p>,
                                        <button className="btn red-button" key="checkoutButton">
                                            <NavLink className="nav-link" to="/menu">Back to Menu</NavLink>
                                        </button>
                                    ]}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal fade" + (this.state.otpSuccessful ? ' show-modal' : '')} tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <h1>OTP Confirmation</h1>
                                <p>
                                    Kindly enter the OTP code sent to the mobile number provided, to confirm the order.
                                </p>
                                <form onSubmit={this.checkCode}>
                                    <div className="form-group">
                                        <input type="number" className="form-control" id="code" required
                                               placeholder="OTP Code" minLength="6" maxLength="6"/>
                                    </div>
                                    {this.state.otpError &&
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.otpMessage}
                                    </div>}
                                    <button className="btn red-button login-button" type="submit"
                                            disabled={this.state.loading}>
                                        {this.state.loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        } Confirm
                                    </button>
                                </form>
                                <button className="btn white-button" key="checkoutButton">
                                    <NavLink className="nav-link" to="/menu">Back to Menu</NavLink>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleOnChange = (payOnDelivery) => {
        this.setState({payOnDelivery: payOnDelivery});
    }

    addOrder = (event) => {
        this.setState({loading: true});
        event.preventDefault();
        let values = {
            email: event.target.email.value,
            firstName: event.target.firstName.value,
            lastName: event.target.lastName.value,
            phone: event.target.phone.value,
            paymentType: event.target.paymentMethod.value,
            deliveryPaymentType: event.target.deliveryPaymentMethod ? event.target.deliveryPaymentMethod.value : null,
            subTotal: this.state.subTotal,
            branch: this.state.branch.id,
            promoCode: this.state.promoCode ? this.state.promoCode.id : null,
            promoCodePercentage: this.state.promoCode ? this.state.promoCode.percentage : null,
            delivery: this.state.deliveryLocation ? this.state.deliveryLocation.id : null,
            deliveryCharge: this.state.deliveryLocation ? this.state.deliveryLocation.amount : null,
            address: this.state.address ? this.state.address.address : null,
            orderType: this.state.address ? 'Delivery' : 'Pickup',
            products: this.state.cart,
            notes: this.state.notes,
            endpoint: '/api/orders'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({error: true, errorMessage: response.data.message});
                } else {
                    updatePromoCode(null, "Checkout").then(() => null);
                    updateNotes("").then(() => null);
                    updateCart([]).then(() => this.props.updateState());
                    this.setState({
                        error: false,
                        errorMessage: null,
                        orderSuccessful: values.paymentType === 'Pay Online',
                        otpSuccessful: values.paymentType !== 'Pay Online',
                        order: response.data.order
                    });
                }
            } else {
                this.setState({error: true, errorMessage: 'An error occurred!'});
            }
            this.setState({loading: false});
        });
    }

    checkCode = (event) => {
        event.preventDefault();
        const code = event.target.code.value;
        if (code.toString().length === 6) {
            this.setState({loading: true});
            let values = {
                otp: code,
                orderId: this.state.order.id,
                endpoint: '/api/otp'
            };
            postRequest(values).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.setState({otpError: true, otpMessage: response.data.message});
                    } else {
                        this.setState({
                            otpError: false,
                            otpMessage: null,
                            orderSuccessful: true,
                            otpSuccessful: false
                        });
                    }
                } else {
                    this.setState({otpError: true, otpMessage: 'An error occurred!'});
                }
                this.setState({loading: false});
            });
        } else {
            this.setState({otpError: true, otpMessage: "Please enter 6 digits"});
        }
    }

    submitIPGForm = () => {
        document.ipgForm.submit();
    }
}

export default withRouter(Checkout);