import React, {Component} from 'react';
import {withAlert} from "react-alert";
import {withRouter} from 'react-router-dom';
import Select from "react-select";
import {deleteRequest, getRequest, postRequest, putRequest, uploadFile} from "../routes/Routes";
import {getBranch, loadBannerImages, loadBranch, loadBranches} from "../common/Common";
import {getUser} from "../auth/Auth";
import '../assets/css/Admin.css';

import addImage from '../assets/images/add.png';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            loading: false,
            action: '',
            branches: [],
            users: [],
            roles: [],
            branch: null,
            user: null,
            bannerImages: [],
            selectedOfferType: 'Combo',
            selectedBuyType: ''
        };
        this.foodFileInput = React.createRef();
        this.offerFileInput = React.createRef();
        this.bannerFileInput = React.createRef();
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            getRequest('/api/users', data.api_token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.props.alert.error(response.data.message);
                    } else {
                        this.setState({users: response.data.users, roles: response.data.roles});
                    }
                } else {
                    this.props.alert.error("An error occurred!");
                }
            });
            getBranch().then(branch => {
                loadBranch(data.role_id === 3 ? branch.id : data.branch_id, data.api_token).then(data => {
                    this.setState({branch: data});
                }).catch(() => null);
            }).catch(() => null);
            this.setState({user: data});
        }).catch(() => null);

        loadBranches().then(data => {
            this.setState({branches: data});
        }).catch(() => null);

        loadBannerImages().then(data => {
            this.setState({bannerImages: data});
        }).catch(() => null);
    }

    render() {
        return (
            <div className="admin-container nunito-sans-font">
                <div className="admin-container-navbar">
                    <div className="vertical-nav">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                             aria-orientation="vertical">
                            {this.state.user && this.state.user.role_id !== 4 && [
                                <a className="nav-link active" id="v-pills-food-types-tab" data-toggle="pill"
                                   href="#v-pills-food-types" role="tab" aria-controls="v-pills-food-types"
                                   aria-selected="true" key="food_types">Food Types</a>,
                                <a className="nav-link" id="v-pills-sub-food-types-tab" data-toggle="pill"
                                   href="#v-pills-sub-food-types" role="tab" aria-controls="v-pills-sub-food-types"
                                   aria-selected="true" key="sub_food_types">Sub Food Types</a>
                            ]}
                            <a className={"nav-link" + (((this.state.user || this.state.user !== null) && this.state.user.role_id === 4) ? " active" : "")}
                               id="v-pills-food-tab" data-toggle="pill" href="#v-pills-food"
                               role="tab" aria-controls="v-pills-food"
                               aria-selected={this.state.user && this.state.user.role_id === 4}>Food</a>
                            {this.state.user && this.state.user.role_id !== 4 && [
                                <a className="nav-link" id="v-pills-promo-codes-tab" data-toggle="pill"
                                   href="#v-pills-promo-codes" role="tab" aria-controls="v-pills-promo-codes"
                                   aria-selected="false" key="promo_code">Promo Codes</a>,
                                <a className="nav-link" id="v-pills-delivery-tab" data-toggle="pill"
                                   href="#v-pills-delivery" role="tab" aria-controls="v-pills-delivery"
                                   aria-selected="false" key="delivery">Delivery</a>
                            ]}
                            <a className="nav-link" id="v-pills-offers-tab" data-toggle="pill" href="#v-pills-offers"
                               role="tab" aria-controls="v-pills-offers" aria-selected="false">Offers</a>
                            {this.state.user && this.state.user.role_id === 3 && [
                                <a className="nav-link" id="v-pills-branches-tab" data-toggle="pill"
                                   href="#v-pills-branches" role="tab" aria-controls="v-pills-branches"
                                   aria-selected="false">Branches</a>,
                                <a className="nav-link" id="v-pills-users-tab" data-toggle="pill"
                                   href="#v-pills-users" role="tab" aria-controls="v-pills-users"
                                   aria-selected="false">Users</a>,
                                <a className="nav-link" id="v-pills-banner-images-tab" data-toggle="pill"
                                   href="#v-pills-banner-images" role="tab" aria-controls="v-pills-banner-images"
                                   aria-selected="false">Banner Images</a>
                            ]}
                        </div>
                    </div>
                    <div className="nav-detail-container">
                        <div className="tab-content admin-tab-content" id="v-pills-tabContent">
                            <div
                                className={"tab-pane fade " + (((this.state.user || this.state.user !== null) && this.state.user.role_id !== 4) ? "show active" : "")}
                                id="v-pills-food-types" role="tabpanel"
                                aria-labelledby="v-pills-food-types-tab">
                                {this.state.action === 'Add New Food Type' ?
                                    <form onSubmit={this.formSubmit} className="admin-form">
                                        <h1 className="form-heading">Add New Food Type</h1>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder='Food Type Name'
                                                   id="name" required/>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="isOffer"/>
                                            <label className="form-check-label" htmlFor="isOffer">Is Offer</label>
                                        </div>
                                        {this.state.success &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMessage}
                                        </div>}
                                        {this.state.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>}
                                        <button className="btn red-button form-button">
                                            {this.state.loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Add
                                        </button>
                                        <button className="btn white-button form-button" id=""
                                                onClick={this.actionClick}>
                                            Cancel
                                        </button>
                                    </form>
                                    : [
                                        <table className="w-100 admin-table" key="food-type-header">
                                            <thead>
                                            <tr>
                                                <th className="admin-table-heading"><h1>Food Type</h1></th>
                                                <th>
                                                    <button className="btn red-button table-button"
                                                            id="Add New Food Type"
                                                            onClick={this.actionClick}>
                                                        Add New
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>,
                                        <table className="w-100 admin-table" key="food-type-body">
                                            <thead>
                                            <tr className="nunito-sans-font">
                                                <th className="red-font">Name</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody className="nunito-sans-font font-weight-bold">
                                            {this.foodTypeTableBody()}
                                            </tbody>
                                        </table>
                                    ]}
                            </div>
                            <div className="tab-pane fade" id="v-pills-sub-food-types" role="tabpanel"
                                 aria-labelledby="v-pills-sub-food-types-tab">
                                {this.state.action === 'Add New Sub Food Type' ?
                                    <form onSubmit={this.formSubmit} className="admin-form">
                                        <h1 className="form-heading">Add New Sub Food Type</h1>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder='Sub Food Type Name'
                                                   id="name" required/>
                                        </div>
                                        <div className="form-group">
                                            <Select className="basic-single btn w-100 select" placeholder="Food Type"
                                                    name="foodType" id="foodType" options={this.state.branch.food_types}
                                                    getOptionValue={option => option['id']}
                                                    getOptionLabel={option => option['name']} required/>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="isOffer"/>
                                            <label className="form-check-label" htmlFor="isOffer">Is Offer</label>
                                        </div>
                                        {this.state.success &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMessage}
                                        </div>}
                                        {this.state.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>}
                                        <button className="btn red-button form-button">
                                            {this.state.loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Add
                                        </button>
                                        <button className="btn white-button form-button" id=""
                                                onClick={this.actionClick}>
                                            Cancel
                                        </button>
                                    </form>
                                    : [
                                        <table className="w-100 admin-table" key="sub-food-type-header">
                                            <thead>
                                            <tr>
                                                <th className="admin-table-heading"><h1>Sub Food Type</h1></th>
                                                <th>
                                                    <button className="btn red-button table-button"
                                                            id="Add New Sub Food Type"
                                                            onClick={this.actionClick}>
                                                        Add New
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>,
                                        <table className="w-100 admin-table" key="sub-food-type-body">
                                            <thead>
                                            <tr className="nunito-sans-font">
                                                <th className="red-font">Name</th>
                                                <th className="red-font">Food Type</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody className="nunito-sans-font font-weight-bold">
                                            {this.subFoodTypeTableBody()}
                                            </tbody>
                                        </table>
                                    ]}
                            </div>
                            <div
                                className={"tab-pane fade " + (((this.state.user || this.state.user !== null) && this.state.user.role_id === 4) ? "show active" : "")}
                                id="v-pills-food" role="tabpanel"
                                aria-labelledby="v-pills-food-tab">
                                {this.state.action === 'Add New Food' ?
                                    <form onSubmit={this.formSubmit} className="admin-form">
                                        <h1 className="form-heading">Add New Food</h1>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder='Food Name'
                                                   id="name" required/>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="number" placeholder='Price'
                                                   id="price" min="0" required/>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="number" placeholder='Daily Quantity'
                                                   id="quantity" min="0" required/>
                                        </div>
                                        <div className="form-group">
                                            <Select className="basic-single btn w-100 select" placeholder="Category"
                                                    name="subFoodType" id="subFoodType"
                                                    options={this.state.branch.sub_food_types}
                                                    getOptionValue={option => option['id']}
                                                    getOptionLabel={option => option['name'] + ' - ' + option['food_type']['name']}
                                                    required/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-text text-muted" htmlFor="open">Open Time</label>
                                            <input className="form-control" type="time" id="open" name="open" required/>
                                            <small id="open" className="form-text text-muted">
                                                Opening time for the food
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-text text-muted" htmlFor="close">Close Time</label>
                                            <input className="form-control" type="time" id="close" name="close"
                                                   required/>
                                            <small id="close" className="form-text text-muted">
                                                Closing time for the food
                                            </small>
                                        </div>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="image" accept="image/*"
                                                   ref={this.foodFileInput}/>
                                            <label className="custom-file-label" htmlFor="image">
                                                <img src={addImage} alt="Add Image Icon"/>
                                                <div className="image-uploader-label">
                                                    Drag & drop or select from your files
                                                </div>
                                            </label>
                                            <small id="image" className="form-text text-muted">
                                                Add Food Image
                                            </small>
                                        </div>
                                        {this.state.success &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMessage}
                                        </div>}
                                        {this.state.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>}
                                        <button className="btn red-button form-button">
                                            {this.state.loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Add
                                        </button>
                                        <button className="btn white-button form-button" id=""
                                                onClick={this.actionClick}>
                                            Cancel
                                        </button>
                                    </form>
                                    : [
                                        <table className="w-100 admin-table" key="food-header">
                                            <thead>
                                            <tr>
                                                <th className="admin-table-heading"><h1>Food</h1></th>
                                                <th>
                                                    <button className="btn red-button table-button" id="Add New Food"
                                                            onClick={this.actionClick}>
                                                        Add New
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>,
                                        <table className="w-100 admin-table" key="food-body">
                                            <thead>
                                            <tr className="nunito-sans-font">
                                                <th className="red-font">Category</th>
                                                <th className="red-font">Name</th>
                                                <th className="red-font">Price</th>
                                                <th className="red-font">Daily Quantity</th>
                                                <th className="red-font">Upload Picture</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody className="nunito-sans-font font-weight-bold">
                                            {this.foodTableBody()}
                                            </tbody>
                                        </table>
                                    ]}
                            </div>
                            <div className="tab-pane fade" id="v-pills-promo-codes" role="tabpanel"
                                 aria-labelledby="v-pills-promo-codes-tab">
                                {this.state.action === 'Add New Promo Code' ?
                                    <form onSubmit={this.formSubmit} className="admin-form">
                                        <h1 className="form-heading">Add New Promo Code</h1>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder='Promo Code Name'
                                                   id="name" required/>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder='Code'
                                                   id="code" required/>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="number" placeholder='Percentage'
                                                   id="percentage" min="0" max="100" required/>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="number" placeholder='Limit'
                                                   id="limit" min="0" required/>
                                        </div>
                                        {this.state.success &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMessage}
                                        </div>}
                                        {this.state.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>}
                                        <button className="btn red-button form-button">
                                            {this.state.loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Add
                                        </button>
                                        <button className="btn white-button form-button" id=""
                                                onClick={this.actionClick}>
                                            Cancel
                                        </button>
                                    </form>
                                    : [
                                        <table className="w-100 admin-table" key="promo-code-header">
                                            <thead>
                                            <tr>
                                                <th className="admin-table-heading"><h1>Promo Codes</h1></th>
                                                <th>
                                                    <button className="btn red-button table-button"
                                                            id="Add New Promo Code"
                                                            onClick={this.actionClick}>
                                                        Add New
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>,
                                        <table className="w-100 admin-table" key="promo-code-body">
                                            <thead>
                                            <tr className="nunito-sans-font">
                                                <th className="red-font">Name</th>
                                                <th className="red-font">Code</th>
                                                <th className="red-font">Percentage</th>
                                                <th className="red-font">Limit</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody className="nunito-sans-font font-weight-bold">
                                            {this.promoCodeTableBody()}
                                            </tbody>
                                        </table>
                                    ]}
                            </div>
                            <div className="tab-pane fade" id="v-pills-delivery" role="tabpanel"
                                 aria-labelledby="v-pills-delivery-tab">
                                {this.state.action === 'Add New Delivery Location' ?
                                    <form onSubmit={this.formSubmit} className="admin-form">
                                        <h1 className="form-heading">Add New Delivery Location</h1>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder='Area'
                                                   id="area" required/>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="number" placeholder='Amount'
                                                   id="amount" min="0" required/>
                                        </div>
                                        {this.state.success &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMessage}
                                        </div>}
                                        {this.state.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>}
                                        <button className="btn red-button form-button">
                                            {this.state.loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Add
                                        </button>
                                        <button className="btn white-button form-button" id=""
                                                onClick={this.actionClick}>
                                            Cancel
                                        </button>
                                    </form>
                                    : [
                                        <table className="w-100 admin-table" key="delivery-header">
                                            <thead>
                                            <tr>
                                                <th className="admin-table-heading"><h1>Delivery Locations</h1></th>
                                                <th>
                                                    <button className="btn red-button table-button"
                                                            id="Add New Delivery Location" onClick={this.actionClick}>
                                                        Add New
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>,
                                        <table className="w-100 admin-table" key="delivery-body">
                                            <thead>
                                            <tr className="nunito-sans-font">
                                                <th className="red-font">Area</th>
                                                <th className="red-font">Delivery Price</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody className="nunito-sans-font font-weight-bold">
                                            {this.deliveryTableBody()}
                                            </tbody>
                                        </table>
                                    ]}
                            </div>
                            <div className="tab-pane fade" id="v-pills-offers" role="tabpanel"
                                 aria-labelledby="v-pills-offers-tab">
                                {this.state.action === 'Add New Offer' ?
                                    <form onSubmit={this.formSubmit} className="admin-form">
                                        <h1 className="form-heading">Add New Offer</h1>
                                        {this.state.selectedOfferType === 'Combo' ? [
                                            <div className="form-group" key="name">
                                                <input className="form-control" type="text" placeholder='Offer Name'
                                                       id="name" required/>
                                            </div>,
                                            <div className="form-group" key="price">
                                                <input className="form-control" type="number" placeholder='Price'
                                                       id="price" min="0" required/>
                                            </div>,
                                            <div className="form-group" key="quantity">
                                                <input className="form-control" type="number"
                                                       placeholder='Daily Quantity'
                                                       id="quantity" min="0" required/>
                                            </div>,
                                            <div className="form-group" key="subFood">
                                                <Select className="basic-single btn w-100 select"
                                                        placeholder="Category"
                                                        name="subFoodType" id="subFoodType"
                                                        options={this.state.branch.offer_sub_food_types}
                                                        getOptionValue={option => option['id']}
                                                        getOptionLabel={option => option['name'] + ' - ' + option['food_type']['name']}
                                                        required/>
                                            </div>,
                                            <div className="form-group" key="openTime">
                                                <label className="form-text text-muted" htmlFor="open">Open
                                                    Time</label>
                                                <input className="form-control" type="time" id="open" name="open"
                                                       required/>
                                                <small id="open" className="form-text text-muted">
                                                    Opening time for the food
                                                </small>
                                            </div>,
                                            <div className="form-group" key="closeTime">
                                                <label className="form-text text-muted" htmlFor="close">Close
                                                    Time</label>
                                                <input className="form-control" type="time" id="close" name="close"
                                                       required/>
                                                <small id="close" className="form-text text-muted">
                                                    Closing time for the food
                                                </small>
                                            </div>,
                                            <div className="custom-file" key="offerImage">
                                                <input type="file" className="custom-file-input" id="offerImage"
                                                       accept="image/*"
                                                       ref={this.offerFileInput}/>
                                                <label className="custom-file-label" htmlFor="offerImage">
                                                    <img src={addImage} alt="Add Image Icon"/>
                                                    <div className="image-uploader-label">
                                                        Drag & drop or select from your files
                                                    </div>
                                                </label>
                                                <small id="image" className="form-text text-muted">
                                                    Add Offer Image
                                                </small>
                                            </div>
                                        ] : this.state.selectedOfferType === 'Buy & Get' ? [
                                            <div className="form-group" key="name">
                                                <input className="form-control" type="text" placeholder='Offer Name'
                                                       id="name" required/>
                                            </div>,
                                            <div className="form-group" key="buyType">
                                                <Select className="basic-single btn w-100 select"
                                                        placeholder="Buy Type"
                                                        name="buyType" id="buyType"
                                                        options={[
                                                            {value: 'Food Type', label: 'Food Type'},
                                                            {value: 'Sub Food Type', label: 'Sub Food Type'},
                                                            {value: 'Food Item', label: 'Food Item'}
                                                        ]}
                                                        onChange={event => this.changeValue('selectedBuyType', event)}
                                                        required/>
                                            </div>,
                                            <div className="form-group" key="buyFood">
                                                {this.state.selectedBuyType === 'Food Type' ?
                                                    <Select className="basic-single btn w-100 select"
                                                            placeholder="Buy Food Type"
                                                            name="foodType" id="foodType"
                                                            options={this.state.branch.food_types}
                                                            getOptionValue={option => option['id']}
                                                            getOptionLabel={option => option['name']} required/>
                                                    : this.state.selectedBuyType === 'Sub Food Type' ?
                                                        <Select className="basic-single btn w-100 select"
                                                                placeholder="Buy Sub Food Type"
                                                                name="subFoodType" id="subFoodType"
                                                                options={this.state.branch.sub_food_types}
                                                                getOptionValue={option => option['id']}
                                                                getOptionLabel={option => option['name'] + ' - ' + option['food_type']['name']}
                                                                required/>
                                                        :
                                                        <Select className="basic-single btn w-100 select"
                                                                placeholder="Buy Food"
                                                                name="food" id="food"
                                                                options={this.state.branch.food}
                                                                getOptionValue={option => option['id']}
                                                                getOptionLabel={option => option['name']}
                                                                required/>
                                                }
                                            </div>,
                                            <div className="form-group" key="buyQuantity">
                                                <input className="form-control" type="number" placeholder='Buy Quantity'
                                                       id="buyQuantity" min="0" required/>
                                            </div>,
                                            <div className="form-group" key="getFood">
                                                <Select className="basic-single btn w-100 select"
                                                        placeholder="Get Food"
                                                        name="getFood" id="getFood"
                                                        options={this.state.branch.food}
                                                        getOptionValue={option => option['id']}
                                                        getOptionLabel={option => option['name']}
                                                        required/>
                                            </div>,
                                            <div className="form-group" key="getQuantity">
                                                <input className="form-control" type="number" placeholder='Get Quantity'
                                                       id="getQuantity" min="0" required/>
                                            </div>
                                        ] : null}
                                        {this.state.success &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMessage}
                                        </div>}
                                        {this.state.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>}
                                        <button className="btn red-button form-button">
                                            {this.state.loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Add
                                        </button>
                                        <button className="btn white-button form-button" id=""
                                                onClick={this.actionClick}>
                                            Cancel
                                        </button>
                                    </form>
                                    : [
                                        <div className="form-group d-flex bd-highlight w-100 select-offer-component"
                                             key="radio">
                                            <div className="p-2 flex-fill bd-highlight custom-control custom-radio">
                                                <input type="radio" id="Combo" name="offerType"
                                                       value="Combo"
                                                       onChange={event => this.changeValue('selectedOfferType', event)}
                                                       className="custom-control-input"
                                                       checked={this.state.selectedOfferType === 'Combo'}/>
                                                <label className="custom-control-label" htmlFor="Combo">Combo</label>
                                            </div>
                                            <div className="p-2 flex-fill bd-highlight custom-control custom-radio">
                                                <input type="radio" id="buyGet" name="offerType"
                                                       value="Buy & Get"
                                                       onChange={event => this.changeValue('selectedOfferType', event)}
                                                       className="custom-control-input"
                                                       checked={this.state.selectedOfferType === 'Buy & Get'}/>
                                                <label className="custom-control-label" htmlFor="buyGet">Buy &
                                                    Get</label>
                                            </div>
                                            <div className="p-2 flex-fill bd-highlight custom-control custom-radio">
                                                <input type="radio" id="payGet" name="offerType"
                                                       value="Pay & Get"
                                                       onChange={event => this.changeValue('selectedOfferType', event)}
                                                       className="custom-control-input"
                                                       checked={this.state.selectedOfferType === 'Pay & Get'}/>
                                                <label className="custom-control-label" htmlFor="payGet">Pay &
                                                    Get</label>
                                            </div>
                                            <div className="p-2 flex-fill bd-highlight custom-control custom-radio">
                                                <input type="radio" id="freeDelivery" name="offerType"
                                                       value="Free Delivery"
                                                       onChange={event => this.changeValue('selectedOfferType', event)}
                                                       className="custom-control-input"
                                                       checked={this.state.selectedOfferType === 'Free Delivery'}/>
                                                <label className="custom-control-label" htmlFor="freeDelivery">Free
                                                    Delivery</label>
                                            </div>
                                        </div>,
                                        <table className="w-100 admin-table" key="offer-header">
                                            <thead>
                                            <tr>
                                                <th className="admin-table-heading"><h1>Offers</h1></th>
                                                <th>
                                                    <button className="btn red-button table-button" id="Add New Offer"
                                                            onClick={this.actionClick}>
                                                        Add New
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>,
                                        this.state.selectedOfferType === 'Combo' ?
                                            <table className="w-100 admin-table" key="offer-body">
                                                <thead>
                                                <tr className="nunito-sans-font">
                                                    <th className="red-font">Category</th>
                                                    <th className="red-font">Name</th>
                                                    <th className="red-font">Price</th>
                                                    <th className="red-font">Daily Quantity</th>
                                                    <th className="red-font">Upload Picture</th>
                                                    <th/>
                                                </tr>
                                                </thead>
                                                <tbody className="nunito-sans-font font-weight-bold">
                                                {this.offerTableBody()}
                                                </tbody>
                                            </table>
                                            : this.state.selectedOfferType === 'Buy & Get' ?
                                            <table className="w-100 admin-table" key="buy-get-offer-body">
                                                <thead>
                                                <tr className="nunito-sans-font">
                                                    <th className="red-font">Name</th>
                                                    <th className="red-font">Buy Type</th>
                                                    <th className="red-font">Buy Item</th>
                                                    <th className="red-font">Buy Quantity</th>
                                                    <th className="red-font">Get Food</th>
                                                    <th className="red-font">Get Quantity</th>
                                                    <th/>
                                                </tr>
                                                </thead>
                                                <tbody className="nunito-sans-font font-weight-bold">
                                                {this.buyGetOfferTableBody()}
                                                </tbody>
                                            </table>
                                            : null
                                    ]}
                            </div>
                            <div className="tab-pane fade" id="v-pills-branches" role="tabpanel"
                                 aria-labelledby="v-pills-branches-tab">
                                {this.state.action === 'Add New Branch' ?
                                    <form onSubmit={this.formSubmit} className="admin-form">
                                        <h1 className="form-heading">Add New Branch</h1>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder='Branch Name'
                                                   id="name" required/>
                                        </div>
                                        <div className="form-group">
                                            <input type="tel" className="form-control"
                                                   placeholder='Phone' id="phone" pattern="[0-9]{10}" required/>
                                            <small id="phone" className="form-text text-muted">
                                                Please follow the pattern (0771234567)
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder='Branch Address'
                                                   id="address" required/>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-text text-muted" htmlFor="open">Open Time</label>
                                            <input className="form-control" type="time" id="open" name="open" required/>
                                            <small id="open" className="form-text text-muted">
                                                Opening time of the branch
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-text text-muted" htmlFor="close">Close Time</label>
                                            <input className="form-control" type="time" id="close" name="close"
                                                   required/>
                                            <small id="close" className="form-text text-muted">
                                                Closing time of the branch
                                            </small>
                                        </div>
                                        {this.state.success &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMessage}
                                        </div>}
                                        {this.state.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>}
                                        <button className="btn red-button form-button">
                                            {this.state.loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Add
                                        </button>
                                        <button className="btn white-button form-button" id=""
                                                onClick={this.actionClick}>
                                            Cancel
                                        </button>
                                    </form>
                                    : [
                                        <table className="w-100 admin-table" key="branch-header">
                                            <thead>
                                            <tr>
                                                <th className="admin-table-heading"><h1>Branches</h1></th>
                                                <th>
                                                    <button className="btn red-button table-button" id="Add New Branch"
                                                            onClick={this.actionClick}>
                                                        Add New
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>,
                                        <table className="w-100 admin-table" key="branch-body">
                                            <thead>
                                            <tr className="nunito-sans-font">
                                                <th className="red-font">Name</th>
                                                <th className="red-font">Phone</th>
                                                <th className="red-font">Address</th>
                                                <th className="red-font">Open</th>
                                                <th className="red-font">Close</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody className="nunito-sans-font font-weight-bold">
                                            {this.branchTableBody()}
                                            </tbody>
                                        </table>
                                    ]}
                            </div>
                            <div className="tab-pane fade" id="v-pills-users" role="tabpanel"
                                 aria-labelledby="v-pills-users-tab">
                                {this.state.action === 'Add New User' ?
                                    <form onSubmit={this.formSubmit} className="admin-form">
                                        <h1 className="form-heading">Add New User</h1>
                                        <div className="form-group">
                                            <input className="form-control" type="text" placeholder='Name'
                                                   id="name" required/>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="email" placeholder='Email'
                                                   id="email" required/>
                                        </div>
                                        <div className="form-group">
                                            <input className="form-control" type="password" placeholder='Password'
                                                   id="password" required/>
                                        </div>
                                        <div className="form-group">
                                            <Select className="basic-single btn w-100 select" placeholder="Role"
                                                    name="role" id="role" options={this.state.roles}
                                                    getOptionValue={option => option['id']}
                                                    getOptionLabel={option => option['name']} required/>
                                        </div>
                                        <div className="form-group">
                                            <Select className="basic-single btn w-100 select" placeholder="Branch"
                                                    name="branch" id="branch" options={this.state.branches}
                                                    getOptionValue={option => option['id']}
                                                    getOptionLabel={option => option['name']}/>
                                            <small id="phone" className="form-text text-muted">
                                                Not required for "SUPER ADMINS"
                                            </small>
                                        </div>
                                        {this.state.success &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMessage}
                                        </div>}
                                        {this.state.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>}
                                        <button className="btn red-button form-button">
                                            {this.state.loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Add
                                        </button>
                                        <button className="btn white-button form-button" id=""
                                                onClick={this.actionClick}>
                                            Cancel
                                        </button>
                                    </form>
                                    : [
                                        <table className="w-100 admin-table" key="user-header">
                                            <thead>
                                            <tr>
                                                <th className="admin-table-heading"><h1>Users</h1></th>
                                                <th>
                                                    <button className="btn red-button table-button" id="Add New User"
                                                            onClick={this.actionClick}>
                                                        Add New
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>,
                                        <table className="w-100 admin-table" key="user-body">
                                            <thead>
                                            <tr className="nunito-sans-font">
                                                <th className="red-font">Name</th>
                                                <th className="red-font">Email</th>
                                                <th className="red-font">Role</th>
                                                <th className="red-font">Branch</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody className="nunito-sans-font font-weight-bold">
                                            {this.userTableBody()}
                                            </tbody>
                                        </table>
                                    ]}
                            </div>
                            <div className="tab-pane fade" id="v-pills-banner-images" role="tabpanel"
                                 aria-labelledby="v-pills-banner-images-tab">
                                {this.state.action === 'Add New Banner Image' ?
                                    <form onSubmit={this.formSubmit} className="admin-form">
                                        <h1 className="form-heading">Add New Banner Image</h1>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="bannerImage"
                                                   accept="image/*"
                                                   ref={this.bannerFileInput} required/>
                                            <label className="custom-file-label" htmlFor="bannerImage">
                                                <img src={addImage} alt="Add Image Icon"/>
                                                <div className="image-uploader-label">
                                                    Drag & drop or select from your files
                                                </div>
                                            </label>
                                            <small id="image" className="form-text text-muted">
                                                Add Banner Image
                                            </small>
                                        </div>
                                        {this.state.success &&
                                        <div className="alert alert-success" role="alert">
                                            {this.state.successMessage}
                                        </div>}
                                        {this.state.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>}
                                        <button className="btn red-button form-button">
                                            {this.state.loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Add
                                        </button>
                                        <button className="btn white-button form-button" id=""
                                                onClick={this.actionClick}>
                                            Cancel
                                        </button>
                                    </form>
                                    : [
                                        <table className="w-100 admin-table" key="banner-image-header">
                                            <thead>
                                            <tr>
                                                <th className="admin-table-heading"><h1>Banner Images</h1></th>
                                                <th>
                                                    <button className="btn red-button table-button"
                                                            id="Add New Banner Image" onClick={this.actionClick}>
                                                        Add New
                                                    </button>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>,
                                        <table className="w-100 admin-table" key="banner-image-body">
                                            <thead>
                                            <tr className="nunito-sans-font">
                                                <th className="red-font">Image</th>
                                                <th/>
                                            </tr>
                                            </thead>
                                            <tbody className="nunito-sans-font font-weight-bold">
                                            {this.bannerImageTableBody()}
                                            </tbody>
                                        </table>
                                    ]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    actionClick = (event) => {
        event.preventDefault();
        this.setState({
            action: event.target.id,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null
        });
    };

    formSubmit = (event) => {
        let {action, branches, users, branch, user, bannerImages, selectedOfferType} = this.state,
            values = null;
        this.setState({loading: true});
        event.preventDefault();
        if (action === 'Add New Food Type') {
            values = {
                name: event.target.name.value,
                isOffer: event.target.isOffer.checked,
                branch: this.state.branch.id,
                endpoint: '/api/food-types'
            };
        } else if (action === 'Add New Sub Food Type') {
            if (event.target.foodType.value === '') {
                this.setState({
                    error: true,
                    errorMessage: "Select a food type!",
                    success: false,
                    successMessage: null,
                    loading: false
                });
                return;
            }
            values = {
                name: event.target.name.value,
                foodType: event.target.foodType.value,
                isOffer: event.target.isOffer.checked,
                endpoint: '/api/sub-food-types'
            };
        } else if (action === 'Add New Delivery Location') {
            values = {
                area: event.target.area.value,
                amount: event.target.amount.value,
                branch: this.state.branch.id,
                endpoint: '/api/deliveries'
            };
        } else if (action === 'Add New Promo Code') {
            values = {
                name: event.target.name.value,
                code: event.target.code.value,
                percentage: event.target.percentage.value,
                limit: event.target.limit.value,
                branch: this.state.branch.id,
                endpoint: '/api/promo-codes'
            };
        } else if (action === 'Add New Branch') {
            values = {
                name: event.target.name.value,
                phone: event.target.phone.value,
                address: event.target.address.value,
                open: event.target.open.value,
                close: event.target.close.value,
                endpoint: '/api/branches'
            };
        } else if (action === 'Add New User') {
            if (event.target.role.value === '') {
                this.setState({
                    error: true,
                    errorMessage: "Select a role!",
                    success: false,
                    successMessage: null,
                    loading: false
                });
                return;
            } else if ((event.target.role.value === '1' || event.target.role.value === '2') && event.target.branch.value === '') {
                this.setState({
                    error: true,
                    errorMessage: "Select a branch!",
                    success: false,
                    successMessage: null,
                    loading: false
                });
                return;
            }
            values = {
                name: event.target.name.value,
                email: event.target.email.value,
                password: event.target.password.value,
                role: event.target.role.value,
                branch: event.target.branch.value === '' ? null : event.target.branch.value,
                endpoint: '/api/users'
            };
        } else if (action === 'Add New Food') {
            if (event.target.subFoodType.value === '') {
                this.setState({
                    error: true,
                    errorMessage: "Select a category!",
                    success: false,
                    successMessage: null,
                    loading: false
                });
                return;
            }
            values = {
                name: event.target.name.value,
                price: event.target.price.value,
                quantity: event.target.quantity.value,
                subFoodType: event.target.subFoodType.value,
                open: event.target.open.value,
                close: event.target.close.value,
                endpoint: '/api/food'
            };
        } else if (action === 'Add New Offer') {
            if (selectedOfferType === 'Combo') {
                if (event.target.subFoodType.value === '') {
                    this.setState({
                        error: true,
                        errorMessage: "Select a category!",
                        success: false,
                        successMessage: null,
                        loading: false
                    });
                    return;
                }
                values = {
                    name: event.target.name.value,
                    price: event.target.price.value,
                    quantity: event.target.quantity.value,
                    subFoodType: event.target.subFoodType.value,
                    open: event.target.open.value,
                    close: event.target.close.value,
                    endpoint: '/api/offers'
                };
            } else if (selectedOfferType === 'Buy & Get') {
                if (event.target.buyType.value === '') {
                    this.setState({
                        error: true,
                        errorMessage: "Select a Buy Type!",
                        success: false,
                        successMessage: null,
                        loading: false
                    });
                    return;
                } else {
                    if (event.target.buyType.value === 'Food Type' && event.target.foodType.value === '') {
                        this.setState({
                            error: true,
                            errorMessage: "Select a Buy Food Type!",
                            success: false,
                            successMessage: null,
                            loading: false
                        });
                        return;
                    } else if (event.target.buyType.value === 'Sub Food Type' && event.target.subFoodType.value === '') {
                        this.setState({
                            error: true,
                            errorMessage: "Select a Buy Sub Food Type!",
                            success: false,
                            successMessage: null,
                            loading: false
                        });
                        return;
                    } else if (event.target.buyType.value === 'Food Item' && event.target.food.value === '') {
                        this.setState({
                            error: true,
                            errorMessage: "Select a Buy Food Item!",
                            success: false,
                            successMessage: null,
                            loading: false
                        });
                        return;
                    }
                }
            }
            values = {
                name: event.target.name.value,
                buyType: event.target.buyType.value,
                buyTypeId: event.target.buyType.value === 'Food Type' ? event.target.foodType.value : event.target.buyType.value === 'Sub Food Type' ? event.target.subFoodType.value : event.target.food.value,
                buyQuantity: event.target.buyQuantity.value,
                getFood: event.target.getFood.value,
                getQuantity: event.target.getQuantity.value,
                endpoint: '/api/buy-get-offers'
            };
        }
        if (action === 'Add New Banner Image') {
            const data = new FormData();
            if (this.bannerFileInput.current.files.length > 0) {
                data.append('image', this.bannerFileInput.current.files[0]);
            }
            uploadFile(data, '/api/banner-images', user.api_token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.setState({
                            error: true,
                            errorMessage: response.data.message,
                            success: false,
                            successMessage: null,
                        });
                    } else {
                        bannerImages.push({...response.data.bannerImage});
                        this.setState({
                            error: false,
                            errorMessage: null,
                            success: true,
                            successMessage: response.data.message,
                            bannerImages: bannerImages
                        });
                    }
                } else {
                    this.setState({error: true, errorMessage: 'An error occurred!'});
                }
            });
            this.setState({loading: false});
        } else {
            values.token = user.api_token;
            postRequest(values).then(async response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.setState({
                            error: true,
                            errorMessage: response.data.message,
                            success: false,
                            successMessage: null,
                        });
                    } else {
                        if (this.state.action === 'Add New Food Type') {
                            branch.food_types.push(response.data.foodType);
                        } else if (this.state.action === 'Add New Sub Food Type') {
                            if (values.isOffer) {
                                branch.offer_sub_food_types.push({
                                    ...response.data.subFoodType,
                                    food_type: response.data.foodType
                                });
                            } else {
                                branch.sub_food_types.push({
                                    ...response.data.subFoodType,
                                    food_type: response.data.foodType
                                });
                            }
                        } else if (this.state.action === 'Add New Delivery Location') {
                            branch.deliveries.push(response.data.delivery);
                        } else if (this.state.action === 'Add New Promo Code') {
                            branch.promo_codes.push(response.data.promoCode);
                        } else if (this.state.action === 'Add New Branch') {
                            branches.push(response.data.branch);
                            window.location.reload();
                        } else if (this.state.action === 'Add New User') {
                            users.push({...response.data.user, role: response.data.role, branch: response.data.branch});
                        } else if (this.state.action === 'Add New Food') {
                            branch.food.push({...response.data.food, sub_food_type: response.data.subFoodType});
                            if (this.foodFileInput.current.files.length > 0) {
                                const data = new FormData();
                                data.append('image', this.foodFileInput.current.files[0]);
                                await uploadFile(data, '/api/food/upload/' + response.data.food.id, user.api_token).then(response => {
                                    if (response.data.error) {
                                        this.setState({
                                            error: true,
                                            errorMessage: response.data.message,
                                            success: false,
                                            successMessage: null,
                                        });
                                    } else {
                                        branch.food[branch.food.length - 1] = {
                                            ...response.data.food,
                                            sub_food_type: branch.food[branch.food.length - 1].sub_food_type
                                        };
                                    }
                                })
                            }
                        } else if (this.state.action === 'Add New Offer') {
                            if (selectedOfferType === 'Combo') {
                                branch.offers.push({...response.data.offer, sub_food_type: response.data.subFoodType});
                                if (this.offerFileInput.current.files.length > 0) {
                                    const data = new FormData();
                                    data.append('image', this.offerFileInput.current.files[0]);
                                    await uploadFile(data, '/api/offers/upload/' + response.data.offer.id, user.api_token).then(response => {
                                        if (response.data.error) {
                                            this.setState({
                                                error: true,
                                                errorMessage: response.data.message,
                                                success: false,
                                                successMessage: null,
                                            });
                                        } else {
                                            branch.offers[branch.offers.length - 1] = {
                                                ...response.data.offer,
                                                sub_food_type: branch.offers[branch.offers.length - 1].sub_food_type
                                            };
                                        }
                                    })
                                }
                            } else if (selectedOfferType === 'Buy & Get') {
                                branch.buy_get_offers.push(response.data.buyGetOffer);
                            }
                        }
                        this.setState({
                            error: false,
                            errorMessage: null,
                            success: true,
                            successMessage: response.data.message,
                            branches: branches,
                            users: users,
                            branch: branch
                        });
                    }
                } else {
                    this.setState({error: true, errorMessage: 'An error occurred!'});
                }
                this.setState({loading: false});
            });
        }
    }

    branchTableBody = () => {
        let body = [];
        this.state.branches.forEach((branch, index) => {
            body.push(
                <tr key={index}>
                    <td>{branch.name}</td>
                    <td>{branch.phone}</td>
                    <td>{branch.address}</td>
                    <td>{branch.open}</td>
                    <td>{branch.close}</td>
                    <td className="action-column red-font">
                        <span onClick={(event) => this.remove(event, branch.id, index, 'branch')}>
                            Remove
                        </span>
                    </td>
                </tr>
            )
        });
        return body;
    }

    remove = (event, id, index, action, isOffer) => {
        event.preventDefault();
        let {branches, users, branch, user, bannerImages, selectedOfferType} = this.state;
        let endpoint = action === 'branch' ? 'branches' : action === 'user' ? 'users' :
            action === 'foodType' ? 'food-types' : action === 'subFoodType' ? 'sub-food-types' :
                action === 'delivery' ? 'deliveries' : action === 'promoCode' ? 'promo-codes' :
                    action === 'food' ? 'food' : action === 'offer' ? (selectedOfferType === 'Combo' ? 'offers' : selectedOfferType === 'Buy & Get' ? 'buy-get-offers' : null) : action === 'bannerImage' ? 'banner-images' : null;
        deleteRequest('/api/' + endpoint + '/' + id, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.props.alert.error(response.data.message);
                } else {
                    if (action === 'branch') {
                        branches.splice(index, 1);
                    } else if (action === 'user') {
                        users.splice(index, 1);
                    } else if (action === 'foodType') {
                        branch.food_types.splice(index, 1);
                    } else if (action === 'subFoodType') {
                        if (isOffer) {
                            branch.offer_sub_food_types.splice(index, 1);
                        } else {
                            branch.sub_food_types.splice(index, 1);
                        }
                    } else if (action === 'delivery') {
                        branch.deliveries.splice(index, 1);
                    } else if (action === 'promoCode') {
                        branch.promo_codes.splice(index, 1);
                    } else if (action === 'food') {
                        branch.food.splice(index, 1);
                    } else if (action === 'offer') {
                        if (selectedOfferType === 'Combo') {
                            branch.offers.splice(index, 1);
                        } else if (selectedOfferType === 'Buy & Get') {
                            branch.buy_get_offers.splice(index, 1);
                        }
                    } else if (action === 'bannerImage') {
                        bannerImages.splice(index, 1);
                    }
                    this.setState({branch: branch, users: users, branches: branches, bannerImages: bannerImages});
                    this.props.alert.success(response.data.message);
                    window.location.reload();
                }
            } else {
                this.props.alert.error("An error occurred!");
            }
        });
    }

    userTableBody = () => {
        let body = [];
        this.state.users.forEach((user, index) => {
            body.push(
                <tr key={index}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.role.name}</td>
                    <td>{user.branch && user.branch.name}</td>
                    <td className="action-column red-font">
                        <span onClick={(event) => this.remove(event, user.id, index, 'user')}>
                            Remove
                        </span>
                    </td>
                </tr>
            )
        });
        return body;
    }

    foodTypeTableBody = () => {
        let body = [];
        if (this.state.branch) {
            this.state.branch.food_types.forEach((foodType, index) => {
                body.push(
                    <tr key={index}>
                        <td>{foodType.name}</td>
                        <td className="action-column red-font">
                        <span onClick={(event) => this.remove(event, foodType.id, index, 'foodType')}>
                            Remove
                        </span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    subFoodTypeTableBody = () => {
        let body = [];
        if (this.state.branch) {
            let array = this.state.branch.sub_food_types.concat(this.state.branch.offer_sub_food_types);
            array.forEach((subFoodType, index) => {
                body.push(
                    <tr key={index}>
                        <td>{subFoodType.name}</td>
                        <td>{subFoodType.food_type.name}</td>
                        <td className="action-column red-font">
                        <span
                            onClick={(event) => this.remove(event, subFoodType.id, index, 'subFoodType', subFoodType.food_type.is_offer)}>
                            Remove
                        </span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    deliveryTableBody = () => {
        let body = [];
        if (this.state.branch) {
            this.state.branch.deliveries.forEach((delivery, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <input className="form-control" type="text" placeholder='Area' id="area" required
                                   value={delivery.area}
                                   onChange={(event) => this.handleChange(event, 'area', index, 'delivery')}/>
                        </td>
                        <td>
                            <input className="form-control" type="number" placeholder='Amount' id="amount" min="0"
                                   value={delivery.amount}
                                   onChange={(event) => this.handleChange(event, 'amount', index, 'delivery')}/>
                        </td>
                        <td className="action-column red-font">
                            <span className="edit-button"
                                  onClick={(event) => this.edit(event, delivery, index, 'delivery')}>
                            Edit
                            </span>
                            <span onClick={(event) => this.remove(event, delivery.id, index, 'delivery')}>
                                Remove
                            </span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    promoCodeTableBody = () => {
        let body = [];
        if (this.state.branch) {
            this.state.branch.promo_codes.forEach((promoCode, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <input className="form-control" type="text" placeholder='Name' id="name" required
                                   value={promoCode.name}
                                   onChange={(event) => this.handleChange(event, 'name', index, 'promoCode')}/>
                        </td>
                        <td>
                            <input className="form-control" type="text" placeholder='Code' id="code" required
                                   value={promoCode.code}
                                   onChange={(event) => this.handleChange(event, 'code', index, 'promoCode')}/>
                        </td>
                        <td>
                            <input className="form-control" type="number" placeholder='Percentage' id="percentage"
                                   required value={promoCode.percentage} min="0" max="100"
                                   onChange={(event) => this.handleChange(event, 'percentage', index, 'promoCode')}/>
                        </td>
                        <td>
                            <input className="form-control" type="number" placeholder='Limit' id="limit"
                                   required value={promoCode.limit} min="0"
                                   onChange={(event) => this.handleChange(event, 'limit', index, 'promoCode')}/>
                        </td>
                        <td className="action-column red-font">
                            <span className="edit-button"
                                  onClick={(event) => this.edit(event, promoCode, index, 'promoCode')}>
                            Edit
                            </span>
                            <span onClick={(event) => this.remove(event, promoCode.id, index, 'promoCode')}>
                            Remove
                            </span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    foodTableBody = () => {
        let body = [];
        if (this.state.branch) {
            this.state.branch.food.forEach((food, index) => {
                let foodFileInput = React.createRef();
                body.push(
                    <tr key={index}>
                        <td className="select-column">
                            <Select className="basic-single btn w-100 select" placeholder="Category"
                                    name="subFoodType" id="subFoodType"
                                    options={this.state.branch.sub_food_types}
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => option['name'] + ' - ' + option['food_type']['name']}
                                    defaultValue={food.sub_food_type}
                                    required
                                    onChange={(event) => this.handleChange(event, 'sub_food_type', index, 'food')}/>
                        </td>
                        <td>
                            <input className="form-control" type="text" placeholder='Name' id="name" required
                                   value={food.name}
                                   onChange={(event) => this.handleChange(event, 'name', index, 'food')}/>
                        </td>
                        <td>
                            <input className="form-control" type="number" placeholder='Price' id="price"
                                   required value={food.price} min="0"
                                   onChange={(event) => this.handleChange(event, 'price', index, 'food')}/>
                            <label className="form-text text-muted" htmlFor="open">Open Time</label>
                            <input className="form-control" type="time" placeholder='Open' id="open"
                                   required value={food.open}
                                   onChange={(event) => this.handleChange(event, 'open', index, 'food')}/>
                            <small id="open" className="form-text text-muted">
                                Opening time for the food
                            </small>
                        </td>
                        <td>
                            <input className="form-control" type="number" placeholder='Quantity' id="quantity"
                                   required value={food.quantity}
                                   onChange={(event) => this.handleChange(event, 'quantity', index, 'food')}/>
                            <label className="form-text text-muted" htmlFor="open">Close Time</label>
                            <input className="form-control" type="time" placeholder='Close' id="close"
                                   required value={food.close}
                                   onChange={(event) => this.handleChange(event, 'close', index, 'food')}/>
                            <small id="close" className="form-text text-muted">
                                Closing time for the food
                            </small>
                        </td>
                        <td className="image-column">
                            {food.image_url ?
                                <img src={food.image_url} alt={food.name + "'s image"}/>
                                : null
                            }
                            <div className="custom-file small-input">
                                <input type="file" className="custom-file-input" id={"foodImage" + index}
                                       accept="image/*"
                                       ref={foodFileInput}
                                       onChange={(event) => this.handleChange(foodFileInput, 'image', index, 'food')}/>
                                <label className="custom-file-label" htmlFor={"foodImage" + index}>
                                    <img src={addImage} alt="Add Image Icon"/>
                                    <div className="image-uploader-label">
                                        Drag & drop or select from your files
                                    </div>
                                </label>
                                <small id="image" className="form-text text-muted">
                                    Add Food Image
                                </small>
                            </div>
                            {this.state.error &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMessage}
                            </div>}
                        </td>
                        <td className="action-column red-font">
                            <span className="edit-button"
                                  onClick={(event) => this.edit(event, food, index, 'food')}>
                            Edit
                            </span>
                            <span onClick={(event) => this.remove(event, food.id, index, 'food')}>
                            Remove
                            </span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    offerTableBody = () => {
        let body = [];
        if (this.state.branch) {
            this.state.branch.offers.forEach((offer, index) => {
                let offerFileInput = React.createRef();
                body.push(
                    <tr key={index}>
                        <td className="select-column">
                            <Select className="basic-single btn w-100 select" placeholder="Category"
                                    name="subFoodType" id="subFoodType"
                                    options={this.state.branch.offer_sub_food_types}
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => option['name'] + ' - ' + option['food_type']['name']}
                                    defaultValue={offer.sub_food_type}
                                    required
                                    onChange={(event) => this.handleChange(event, 'sub_food_type', index, 'offer')}/>
                        </td>
                        <td>
                            <input className="form-control" type="text" placeholder='Name' id="name" required
                                   value={offer.name}
                                   onChange={(event) => this.handleChange(event, 'name', index, 'offer')}/>
                        </td>
                        <td>
                            <input className="form-control" type="number" placeholder='Price' id="price"
                                   required value={offer.price} min="0"
                                   onChange={(event) => this.handleChange(event, 'price', index, 'offer')}/>
                            <label className="form-text text-muted" htmlFor="open">Open Time</label>
                            <input className="form-control" type="time" placeholder='Open' id="open"
                                   required value={offer.open}
                                   onChange={(event) => this.handleChange(event, 'open', index, 'offer')}/>
                            <small id="open" className="form-text text-muted">
                                Opening time for the offer
                            </small>
                        </td>
                        <td>
                            <input className="form-control" type="number" placeholder='Quantity' id="quantity"
                                   required value={offer.quantity}
                                   onChange={(event) => this.handleChange(event, 'quantity', index, 'offer')}/>
                            <label className="form-text text-muted" htmlFor="close">Close Time</label>
                            <input className="form-control" type="time" placeholder='Close' id="close"
                                   required value={offer.close}
                                   onChange={(event) => this.handleChange(event, 'close', index, 'offer')}/>
                            <small id="close" className="form-text text-muted">
                                Closing time for the offer
                            </small>
                        </td>
                        <td className="image-column">
                            {offer.image_url ?
                                <img src={offer.image_url} alt={offer.name + "'s image"}/>
                                : null
                            }
                            <div className="custom-file small-input">
                                <input type="file" className="custom-file-input" id={"offerImage" + index}
                                       accept="image/*"
                                       ref={offerFileInput}
                                       onChange={(event) => this.handleChange(offerFileInput, 'image', index, 'offer')}/>
                                <label className="custom-file-label" htmlFor={"offerImage" + index}>
                                    <img src={addImage} alt="Add Image Icon"/>
                                    <div className="image-uploader-label">
                                        Drag & drop or select from your files
                                    </div>
                                </label>
                                <small id="image" className="form-text text-muted">
                                    Add Offer Image
                                </small>
                                {this.state.error &&
                                <div className="alert alert-danger" role="alert">
                                    {this.state.errorMessage}
                                </div>}
                            </div>
                        </td>
                        <td className="action-column red-font">
                            <span className="edit-button"
                                  onClick={(event) => this.edit(event, offer, index, 'offer')}>
                            Edit
                            </span>
                            <span onClick={(event) => this.remove(event, offer.id, index, 'offer')}>
                            Remove
                            </span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    buyGetOfferTableBody = () => {
        let body = [];
        if (this.state.branch) {
            this.state.branch.buy_get_offers.forEach((offer, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <input className="form-control" type="text" placeholder='Name' id="name" required
                                   value={offer.name}
                                   onChange={(event) => this.handleChange(event, 'name', index, 'offer')}/>
                        </td>
                        <td className="select-column">
                            <Select className="basic-single btn w-100 select" placeholder="Buy Type"
                                    name="buyType" id="buyType"
                                    options={[
                                        {value: 'Food Type', label: 'Food Type'},
                                        {value: 'Sub Food Type', label: 'Sub Food Type'},
                                        {value: 'Food Item', label: 'Food Item'}
                                    ]}
                                    defaultValue={{value: offer.buy_type, label: offer.buy_type}} required
                                    onChange={(event) => this.handleChange(event, 'buy_type', index, 'offer')}/>
                        </td>
                        <td className="select-column">
                            {offer.buy_type === 'Food Type' ?
                                <Select className="basic-single btn w-100 select" placeholder="Buy Food Type"
                                        name="foodType" id="foodType"
                                        options={this.state.branch.food_types}
                                        getOptionValue={option => option['id']}
                                        getOptionLabel={option => option['name']}
                                        defaultValue={this.state.branch.food_types[this.state.branch.food_types.findIndex(function (item) {
                                            return (item.id === offer.buy_type_id);
                                        })]}
                                        required
                                        onChange={(event) => this.handleChange(event, 'buy_type_id', index, 'offer')}/>
                                : offer.buy_type === 'Sub Food Type' ?
                                    <Select className="basic-single btn w-100 select" placeholder="Buy Sub Food Type"
                                            name="subFoodType" id="subFoodType"
                                            options={this.state.branch.sub_food_types}
                                            getOptionValue={option => option['id']}
                                            getOptionLabel={option => option['name']}
                                            defaultValue={this.state.branch.sub_food_types[this.state.branch.sub_food_types.findIndex(function (item) {
                                                return (item.id === offer.buy_type_id);
                                            })]}
                                            required
                                            onChange={(event) => this.handleChange(event, 'buy_type_id', index, 'offer')}/>
                                    : <Select className="basic-single btn w-100 select" placeholder="Buy Food"
                                              name="food" id="food"
                                              options={this.state.branch.food}
                                              getOptionValue={option => option['id']}
                                              getOptionLabel={option => option['name']}
                                              defaultValue={this.state.branch.food[this.state.branch.food.findIndex(function (item) {
                                                  return (item.id === offer.buy_type_id);
                                              })]}
                                              required
                                              onChange={(event) => this.handleChange(event, 'buy_type_id', index, 'offer')}/>
                            }
                        </td>
                        <td>
                            <input className="form-control" type="number" placeholder='Buy Quantity' id="buyQuantity"
                                   required value={offer.buy_quantity}
                                   onChange={(event) => this.handleChange(event, 'buy_quantity', index, 'offer')}/>
                        </td>
                        <td className="select-column">
                            <Select className="basic-single btn w-100 select" placeholder="Get Food"
                                    name="getFood" id="getFood"
                                    options={this.state.branch.food}
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => option['name']}
                                    defaultValue={offer.food}
                                    required
                                    onChange={(event) => this.handleChange(event, 'get_food_id', index, 'offer')}/>
                        </td>
                        <td>
                            <input className="form-control" type="number" placeholder='Get Quantity' id="getQuantity"
                                   required value={offer.get_quantity}
                                   onChange={(event) => this.handleChange(event, 'get_quantity', index, 'offer')}/>
                        </td>
                        <td className="action-column red-font">
                            <span className="edit-button"
                                  onClick={(event) => this.edit(event, offer, index, 'offer')}>
                            Edit
                            </span>
                            <span onClick={(event) => this.remove(event, offer.id, index, 'offer')}>
                            Remove
                            </span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    bannerImageTableBody = () => {
        let body = [];
        this.state.bannerImages.forEach((bannerImage, index) => {
            let bannerFileInput = React.createRef();
            body.push(
                <tr key={index}>
                    <td className="image-column">
                        {bannerImage.image_url ?
                            <img src={bannerImage.image_url} alt={"banner " + bannerImage.id + " image"}/>
                            : null
                        }
                        <div className="custom-file small-input banner-image-input">
                            <input type="file" className="custom-file-input" id={"bannerImage" + index}
                                   accept="image/*"
                                   ref={bannerFileInput}
                                   onChange={(event) => this.handleChange(bannerFileInput, 'image', index, 'bannerImage')}/>
                            <label className="custom-file-label" htmlFor={"bannerImage" + index}>
                                <img src={addImage} alt="Add Image Icon"/>
                                <div className="image-uploader-label">
                                    Drag & drop or select from your files
                                </div>
                            </label>
                            <small id="image" className="form-text text-muted">
                                Add Banner Image
                            </small>
                            {this.state.error &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMessage}
                            </div>}
                        </div>
                    </td>
                    <td className="action-column red-font">
                        <span className="edit-button"
                              onClick={(event) => this.edit(event, bannerImage, index, 'bannerImage')}>
                            Edit
                            </span>
                        <span onClick={(event) => this.remove(event, bannerImage.id, index, 'bannerImage')}>
                            Remove
                        </span>
                    </td>
                </tr>
            )
        });
        return body;
    }

    handleChange(event, key, index, action) {
        let {branch, bannerImages, selectedOfferType} = this.state;
        if (action === 'delivery') {
            branch.deliveries[index][key] = event.target.value;
        } else if (action === 'promoCode') {
            branch.promo_codes[index][key] = event.target.value;
        } else if (action === 'food') {
            if (key === 'image') {
                branch.food[index][key] = event.current.files[0];
            } else if (key === 'sub_food_type') {
                branch.food[index][key] = event;
                branch.food[index]['sub_food_type_id'] = event.id;
            } else {
                branch.food[index][key] = event.target.value;
            }
        } else if (action === 'offer') {
            if (selectedOfferType === 'Combo') {
                if (key === 'image') {
                    branch.offers[index][key] = event.current.files[0];
                } else if (key === 'sub_food_type') {
                    branch.offers[index][key] = event;
                    branch.offers[index]['sub_food_type_id'] = event.id;
                } else {
                    branch.offers[index][key] = event.target.value;
                }
            } else if (selectedOfferType === 'Buy & Get') {
                if (key === 'buy_type') {
                    branch.buy_get_offers[index][key] = event.value;
                } else if (key === 'buy_type_id') {
                    branch.buy_get_offers[index][key] = event.id;
                } else if (key === 'get_food_id') {
                    branch.buy_get_offers[index][key] = event.id;
                    branch.buy_get_offers[index]['food'] = event;
                } else {
                    branch.buy_get_offers[index][key] = event.target.value;
                }
            }
        } else if (action === 'bannerImage') {
            if (key === 'image') {
                bannerImages[index][key] = event.current.files[0];
            }
        }
        this.setState({branch: branch, bannerImages: bannerImages});
    }

    edit = (event, values, index, action) => {
        event.preventDefault();
        let {branch, user, bannerImages, selectedOfferType} = this.state;
        let endpoint = action === 'delivery' ? 'deliveries' : action === 'promoCode' ? 'promo-codes' :
            action === 'food' ? 'food' : action === 'offer' ? (selectedOfferType === 'Combo' ? 'offers' : selectedOfferType === 'Buy & Get' ? 'buy-get-offers' : null) : action === 'bannerImage' ? 'banner-images' : null;
        values.endpoint = '/api/' + endpoint + '/' + values.id;
        values.token = user.api_token;
        if (action === 'bannerImage') {
            if (values.image) {
                const data = new FormData();
                data.append('image', values.image);
                uploadFile(data, '/api/banner-images/update/' + values.id, user.api_token).then(response => {
                    if (response.status === 200) {
                        if (response.data.error) {
                            this.props.alert.error(response.data.message);
                        } else {
                            bannerImages[index] = response.data.bannerImage;
                            this.setState({bannerImages: bannerImages});
                            this.props.alert.success(response.data.message);
                        }
                    } else {
                        this.props.alert.error("An error occurred!");
                    }
                })
            }
        } else {
            putRequest(values).then(async response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.props.alert.error(response.data.message);
                    } else {
                        if (action === 'delivery') {
                            branch.deliveries[index] = response.data.delivery;
                        } else if (action === 'promoCode') {
                            branch.promo_codes[index] = response.data.promoCode;
                        } else if (action === 'food') {
                            branch.food[index] = response.data.food;
                            if (values.image) {
                                const data = new FormData();
                                data.append('image', values.image);
                                await uploadFile(data, '/api/food/upload/' + response.data.food.id, user.api_token).then(response => {
                                    if (response.data.error) {
                                        this.setState({
                                            error: true,
                                            errorMessage: response.data.message,
                                            success: false,
                                            successMessage: null,
                                        });
                                    } else {
                                        branch.food[index] = response.data.food;
                                    }
                                })
                            }
                        } else if (action === 'offer') {
                            if (selectedOfferType === 'Combo') {
                                branch.offers[index] = response.data.offer;
                                if (values.image) {
                                    const data = new FormData();
                                    data.append('image', values.image);
                                    await uploadFile(data, '/api/offers/upload/' + response.data.offer.id, user.api_token).then(response => {
                                        if (response.data.error) {
                                            this.setState({
                                                error: true,
                                                errorMessage: response.data.message,
                                                success: false,
                                                successMessage: null,
                                            });
                                        } else {
                                            branch.offers[index] = response.data.offer;
                                        }
                                    })
                                }
                            } else if (selectedOfferType === 'Buy & Get') {
                                branch.buy_get_offers[index] = response.data.buyGetOffer;
                            }
                        }
                        this.setState({branch: branch});
                        this.props.alert.success(response.data.message);
                    }
                } else {
                    this.props.alert.error("An error occurred!");
                }
            });
        }
    }

    changeValue = (key, event) => {
        this.setState({[key]: event.target ? event.target.value : event.value});
    }
}

export default withAlert()(withRouter(Admin));