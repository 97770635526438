import React, {Component} from 'react';
import Footer from "./Footer";
import '../assets/css/AboutUs.css';

class PrivacyPolicy extends Component {
    render() {
        return ([
            <div key="body">
                <div className="about-us-container container">
                    <div className="about-us-detail-container">
                        <h1>Privacy Policy</h1>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Release of customer information</h1>
                        <p className="nunito-sans-font">
                            The collection of personal information is a retort of voluntary action to complete a
                            transaction or inquiry on the website. Personal details such as Name, Postal address, E Mail
                            address and contact details would be required to operate most of the website’s functions.
                            The objection of providing the highlighted details may limit the functionality of the
                            Website as a whole. Furthermore, IP addresses used to place orders would be automatically
                            identified by our web servers.
                        </p>
                        <p className="nunito-sans-font">We reserve the right to utilize customer information to:</p>
                        <ul className="nunito-sans-font">
                            <li>Inform customers on latest promotions, deals and any marketing material put forward by
                                Burger hut.
                            </li>
                            <li>To receive feedback by customer to improve website, products & services by us.</li>
                        </ul>
                    </div>
                </div>
            </div>,
            <div id="footer" key="footer">
                <Footer/>
            </div>
        ])
    }
}

export default PrivacyPolicy;