import React, {Component} from 'react';
import {withAlert} from "react-alert";
import {withRouter} from "react-router-dom";
import {getRequest} from "../routes/Routes";
import Loading from '../components/Loading';
import '../assets/css/Order.css';

class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {order: null, loading: false};
    }

    componentDidMount() {
        this.setState({loading: true});
        getRequest('/api/orders/' + this.props.match.params.orderNumber).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.props.alert.error(response.data.message);
                } else {
                    this.setState({order: response.data.order});
                }
            } else {
                this.props.alert.error("An error occurred!");
            }
            this.setState({loading: false});
        });
    }

    render() {
        if (this.state.loading) {
            return (<Loading/>);
        } else {
            if (this.state.order) {
                return (
                    <div className="container order-container nunito-sans-font">
                        <div className="d-flex bd-highlight w-100">
                            <div className="p-2 flex-fill bd-highlight">
                                <h1>Order Status : &nbsp;
                                    <span
                                        className={"nunito-sans-font " + (this.state.order.status === 'Payment Pending' ? 'PaymentPending' : this.state.order.status === 'Payment Failed' ? 'Failed' : this.state.order.status)}>
                                    {this.state.order.status}
                                </span>
                                </h1>
                                <h2>Order By :</h2>
                                <br/>
                                <h4 className="nunito-sans-font">{this.state.order.first_name + ' ' + this.state.order.last_name}</h4>
                                <p className="nunito-sans-font">{this.state.order.email}</p>
                                <p className="nunito-sans-font">{this.state.order.phone}</p>
                                <br/>
                                <h2>Order Information :</h2>
                                <br/>
                                <p>
                                    <span className="font-weight-bold">Order No. : </span>
                                    <span className="nunito-sans-font">{this.state.order.reference}</span>
                                </p>
                                <p>
                                    <span className="font-weight-bold">Order Type : </span>
                                    <span className="nunito-sans-font">{this.state.order.order_type}</span>
                                </p>
                                <p>
                                    <span className="font-weight-bold">Date & Time : </span>
                                    <span
                                        className="nunito-sans-font">{(new Date(this.state.order.created_at)).toDateString() + " " + (new Date(this.state.order.created_at)).toLocaleTimeString()}</span>
                                </p>
                                <p>
                                    <span className="font-weight-bold">Payment Type : </span>
                                    <span className="nunito-sans-font">{this.state.order.payment_type}</span>
                                </p>
                                <p>
                                    <span className="font-weight-bold">Notes : </span>
                                    <span className="nunito-sans-font">{this.state.order.notes}</span>
                                </p>
                            </div>
                            <div className="p-2 flex-fill bd-highlight order-table-container">
                                <div>
                                    <table className="order-table w-100">
                                        <tbody>
                                        {this.tableBody()}
                                        <tr className="total-row">
                                            <td/>
                                            <td/>
                                            <td>Sub Total</td>
                                            <td>{this.state.order.sub_total}</td>
                                        </tr>
                                        <tr>
                                            <td/>
                                            <td/>
                                            <td>Discount</td>
                                            <td>({this.state.order.sub_total * (this.state.order.promo_code_percentage / 100)})</td>
                                        </tr>
                                        <tr>
                                            <td/>
                                            <td/>
                                            <td>Delivery Charges</td>
                                            <td>{this.state.order.delivery_charge}</td>
                                        </tr>
                                        <tr>
                                            <td/>
                                            <td/>
                                            <td className="red-font">Total</td>
                                            <td className="red-font">
                                                {(this.state.order.sub_total - (this.state.order.sub_total * (this.state.order.promo_code_percentage / 100))) + this.state.order.delivery_charge}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="container order-container">
                        <h1>Order Not Found!</h1>
                    </div>
                );
            }
        }
    }

    tableBody = () => {
        let body = [];
        this.state.order.order_products.forEach((product, index) => {
            body.push(
                <tr key={index}>
                    <td className="capital-text food-name-column">{product.food.name}</td>
                    <td>{product.price}</td>
                    <td>{product.quantity}</td>
                    <td>{product.price * product.quantity}</td>
                </tr>
            )
        });
        this.state.order.order_offers.forEach((offer, index) => {
            body.push(
                <tr key={index}>
                    <td className="capital-text food-name-column">{offer.offer.name}</td>
                    <td>{offer.price}</td>
                    <td>{offer.quantity}</td>
                    <td>{offer.price * offer.quantity}</td>
                </tr>
            )
        });
        return body;
    }
}

export default withAlert()(withRouter(Order));