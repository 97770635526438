import React, {Component} from 'react';
import {Carousel} from 'react-responsive-carousel';
import CartComponent from "../components/CartComponent";
import FoodList from "../components/FoodList";
import {
    getBranch,
    loadBannerImages,
    loadBranchBuyGetOffers,
    loadBranchFoodTypes,
    loadFoodTypeDetails, updateBuyGetOffers
} from "../common/Common";
import Loading from '../components/Loading';
import '../assets/css/Menu.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branch: null,
            foodTypes: [],
            foodTypeList: null,
            isMouseDown: false,
            startX: null,
            scrollLeft: null,
            selectedFoodType: null,
            foodTypeDetails: null,
            loading: true,
            count: 0,
            bannerImages: []
        };
    }

    componentDidMount() {
        getBranch().then(branch => {
            loadBranchFoodTypes(branch.id).then(foodTypes => {
                this.setState({branch: branch, foodTypes: foodTypes});
                this.updateSelectedFoodType(null, null).then(() => null);
            }).catch(() => null);
            loadBranchBuyGetOffers(branch.id).then(buyGetOffers => {
                updateBuyGetOffers(buyGetOffers).then(null);
            }).catch(() => null);
        }).catch(() => null);
        this.setState({foodTypeList: document.querySelector('.food-type-list')});
        loadBannerImages().then(data => {
            this.setState({bannerImages: data});
        }).catch(() => null);
    }

    render() {
        return (
            <div className="container">
                <div className="d-flex bd-highlight w-100">
                    <div className="p-2 flex-fill bd-highlight menu-container">
                        <Carousel showThumbs={false} showArrows={false} autoPlay={true} infiniteLoop={true}
                                  showStatus={false}>
                            {this.bannerImagesBody()}
                        </Carousel>
                        <div className="menu">
                            <div className="food-type-list" onMouseDown={this.mouseDownAction}
                                 onMouseLeave={this.mouseUpAction} onMouseUp={this.mouseUpAction}
                                 onMouseMove={this.mouseMoveAction}>
                                {this.foodTypeListBody()}
                            </div>
                            <div className="food-type-detail-list-container">
                                {this.state.loading ?
                                    <Loading/>
                                    : this.state.foodTypeDetails && this.state.foodTypeDetails.length > 0 ?
                                        this.foodTypeDetailsBody()
                                        : <p>No {this.props.isFoodComponent ? 'Food ' : 'Offers '} Available</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="p-2 flex-fill bd-highlight cart-page-container desktop-cart-page-container">
                        <CartComponent isCartPage={false} count={this.state.count} updateState={this.updateState}/>
                    </div>
                </div>
            </div>
        )
    }

    foodTypeListBody = () => {
        let body = [];
        body.push(
            <button className={"btn " + (!this.state.selectedFoodType ? "red-button" : "black-font-button")} key="all"
                    onClick={(event) => this.updateSelectedFoodType(null, null)}>
                All
            </button>
        )
        this.state.foodTypes.forEach((foodType, index) => {
            if ((this.props.isFoodComponent && !foodType.is_offer) || (!this.props.isFoodComponent && foodType.is_offer)) {
                body.push(
                    <button className={"btn capital-text " + (foodType.selected ? "red-button" : "black-font-button")}
                            key={index}
                            onClick={(event) => this.updateSelectedFoodType(foodType, index)}>
                        {foodType.name}
                    </button>
                )
            }
        });
        return body;
    }

    updateSelectedFoodType = async (foodType, index) => {
        this.setState({loading: true});
        let {foodTypes, selectedFoodType, foodTypeDetails, branch} = this.state;
        foodTypes.find((element) => {
            if (element === selectedFoodType) {
                element.selected = false;
            }
            return null;
        });
        if (foodType) {
            foodTypes[index].selected = true;
            await loadFoodTypeDetails(foodType.id).then(response => {
                foodTypeDetails = response;
            }).catch(() => null);
        } else if (branch) {
            await loadBranchFoodTypes(branch.id).then(response => {
                foodTypeDetails = response;
            }).catch(() => null);
        }

        this.setState({
            selectedFoodType: foodType,
            foodTypes: foodTypes,
            foodTypeDetails: foodTypeDetails,
            loading: false
        });
    }

    foodTypeDetailsBody = () => {
        let body = [],
            {branch} = this.state;
        if (this.state.foodTypeDetails) {
            this.state.foodTypeDetails.forEach((foodType, index) => {
                if ((this.props.isFoodComponent && !foodType.is_offer) || (!this.props.isFoodComponent && foodType.is_offer)) {
                    let subFoodTypeBody = [];
                    if (foodType.sub_food_types.length > 0) {
                        foodType.sub_food_types.forEach((subFoodType, index) => {
                            subFoodTypeBody.push(
                                this.props.isFoodComponent ?
                                    <div key={index}>
                                        <h2 className="capital-text">{subFoodType.name}</h2>
                                        <FoodList food={subFoodType.food} branch={branch}
                                                  updateState={this.updateState}/>
                                    </div>
                                    : subFoodType.offers.length > 0 ?
                                    <div key={index}>
                                        <h2 className="capital-text">{subFoodType.name}</h2>
                                        <FoodList food={subFoodType.offers}
                                                  branch={branch} updateState={this.updateState}/>
                                    </div>
                                    : null
                            )
                        });
                    } else {
                        subFoodTypeBody.push(<p>No {this.props.isFoodComponent ? 'Food ' : 'Offers '} Available</p>)
                    }
                    body.push(
                        <div key={index} className="food-type-detail-container">
                            <h1 className="capital-text">{foodType.name}</h1>
                            {subFoodTypeBody}
                        </div>
                    )
                }
            });
        }
        return body;
    }

    mouseDownAction = (event) => {
        event.preventDefault();
        let {foodTypeList} = this.state;
        let isMouseDown = true;
        foodTypeList.classList.add('active');
        let startX = event.pageX - foodTypeList.offsetLeft;
        let scrollLeft = foodTypeList.scrollLeft;
        this.setState({foodTypeList: foodTypeList, isMouseDown: isMouseDown, startX: startX, scrollLeft: scrollLeft})
    }

    mouseUpAction = (event) => {
        event.preventDefault();
        let {foodTypeList} = this.state;
        let isMouseDown = false;
        foodTypeList.classList.remove('active');
        this.setState({foodTypeList: foodTypeList, isMouseDown: isMouseDown})
    }

    mouseMoveAction = (event) => {
        event.preventDefault();
        let {foodTypeList, isMouseDown, startX, scrollLeft} = this.state;
        if (!isMouseDown) return;
        const x = event.pageX - foodTypeList.offsetLeft;
        const walk = (x - startX) * 3;
        foodTypeList.scrollLeft = scrollLeft - walk;
        this.setState({foodTypeList: foodTypeList, isMouseDown: isMouseDown, startX: startX, scrollLeft: scrollLeft})
    }

    updateState = () => {
        let {count} = this.state;
        this.setState({count: ++count});
        this.props.updateState();
    }

    bannerImagesBody = () => {
        let body = [];
        this.state.bannerImages.forEach((bannerImage, index) => {
            body.push(
                <div key={index}>
                    <img src={bannerImage.image_url}/>
                </div>
            );
        });
        return body;
    }
}

export default Menu;