import React, {Component} from 'react';
import Footer from "./Footer";
import '../assets/css/AboutUs.css';
import offerBanner from '../assets/images/special-offer-banner.png';

class AboutUs extends Component {
    render() {
        return ([
            <div key="body">
                <div className="about-us-container container">
                    <img src={offerBanner} alt="Offer Banner"/>
                    <div className="about-us-detail-container">
                        <h1>How it Started?</h1>
                        <p className="nunito-sans-font">
                            Burger Hut, A brand built on hard work, perseverance and creating multi cultural cuisines to
                            suit the Sri Lankan palette. The story of Burger Hut is based on years of experience, 14
                            years of experience to be precise before the first outlet bearing the name Burger Hut came
                            to life in 2010 at Akurana, Kandy. Times were tough with competition and Burger Hut’s unique
                            selling point was infusing multicultural cuisine with local palettes. Fast forward 10 years
                            and Burger Hut is now 5 outlets strong and has provided employment to over 200 individuals.
                            Amidst the size of our operations we always aim to be the benchmark in terms of service and
                            quality in our respective industry.
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Mission</h1>
                        <p className="nunito-sans-font">
                            Creating value in every meal and be a specialist in the fast food industry by incorporating
                            the best international standards to Sri Lanka.
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Vision</h1>
                        <p className="nunito-sans-font">
                            Connecting multicultural cuisines to the Sri Lankan palette while infusing a fine dining
                            theme.
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Why Us?</h1>
                        <p className="nunito-sans-font">
                            We strive to create value with every purchase made by giving our customers the best in terms
                            of taste and money spent on every meal. Our multicultural cuisine is curated by our team who
                            has been perfecting our menu for 14 years and with a mission to incorporate flavours from
                            around the world to suit the Sri Lankan in us. We are 5 outlets strong and expanding to
                            serve you better and we would love to see you being a part of our unique journey
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>A word from our Head Chef/Founder</h1>
                        <p className="nunito-sans-font">
                            The world has many ideations of how a meal should be prepared. Different cultures and
                            countries have their own perception. 24 years ago my mission was to experience multicultural
                            cuisines and create a style of food suited to my home country Sri Lanka and today that is a
                            reality. Every single dish that leaves the Burger Hut kitchen in any outlet is a
                            multicultural gastronomical treat suited to the Sri Lankan palette. The next time you decide
                            to choose Burger Hut always remember you are experiencing a treat from multiple countries.
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Locations</h1>
                        <p className="nunito-sans-font">
                            As the Central Province was Home to Burger Hut’s Origin, majority of Burger Huts are
                            currently resting in the hillside from Akurana, Katugastotha to Peradeniya. Burger Hut’s
                            first expansion to Colombo was to Dehiwala, and within the 4th year we were able to launch
                            our latest at Marine Drive.
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Contact Us</h1>
                        <p className="nunito-sans-font">
                            0112 505 888 <br/>
                            management@thesignature.lk <br/>
                            No. 32 Kingross Avenue, Bambalapitya, Colombo 04
                        </p>
                    </div>
                </div>
            </div>,
            <div id="footer" key="footer">
                <Footer/>
            </div>
        ])
    }
}

export default AboutUs;