import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {CSVLink} from "react-csv";
import {getUser} from "../auth/Auth";
import {getRequest} from "../routes/Routes";
import {getBranch} from "../common/Common";
import {withAlert} from "react-alert";
import dateformat from "dateformat";
import '../assets/css/Admin.css';

class Finance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            filteredOrders: [],
            user: null,
            branch: null,
            order: null,
            orderNoFilterValue: '',
            customerFilterValue: '',
            paymentTypeFilterValue: 'All',
            dateFilterValue: dateformat(new Date(), "yyyy-mm-dd"),
            dateFilteredOrders: [],
            statistics: {
                totalOrders: 0,
                totalConfirmedOrders: 0,
                totalPayOnlineOrders: 0,
                totalPayOnDeliveryOrders: 0,
                totalPaidOnline: 0,
                totalPaidOnDelivery: 0
            },
            csvData: []
        };
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            getBranch().then(branch => {
                this.setState({branch: branch});
                this.loadOrders();
            }).catch(() => null);
            this.setState({user: data});
        }).catch(() => null);
        this.interval = setInterval(() => {
            this.loadOrders();
            this.calculateStatistics();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        let {order, statistics, csvData} = this.state;
        return (
            <div className="admin-container nunito-sans-font">
                <div className="admin-container-navbar">
                    <div className="vertical-nav">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                             aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-finance-portal-tab" data-toggle="pill"
                               href="#v-pills-finance-portal" role="tab" aria-controls="v-pills-finance-portal"
                               aria-selected="true">Finance Portal</a>
                        </div>
                    </div>
                    <div className="nav-detail-container portal-nav-detail-container">
                        <div className="tab-content container" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-finance-portal" role="tabpanel"
                                 aria-labelledby="v-pills-finance-portal-tab">
                                <table className="w-100 admin-table">
                                    <thead>
                                    <tr>
                                        <th className="admin-table-heading"><h1>Order History</h1></th>
                                        <th className="filter-input-column">
                                            <input className="form-control" type="text" placeholder='Order No'
                                                   id="orderNo" value={this.state.orderNoFilterValue}
                                                   onChange={(event => this.handleFilterChange(event, 'Order No'))}/>
                                        </th>
                                        <th className="filter-input-column">
                                            <input className="form-control" type="text" placeholder='Customer Name'
                                                   id="name" value={this.state.customerFilterValue}
                                                   onChange={(event => this.handleFilterChange(event, 'Customer'))}/>
                                        </th>
                                        <th className="filter-input-column">
                                            <select className="form-control" value={this.state.paymentTypeFilterValue}
                                                    onChange={(event => this.handleFilterChange(event, 'Payment Type'))}
                                                    id="paymentType">
                                                <option value="All">All</option>
                                                <option value="Pay Online">Pay Online</option>
                                                <option value="Pay On Delivery">Pay On Delivery</option>
                                                <option value="Pay On Pickup">Pay On Pickup</option>
                                            </select>
                                        </th>
                                        <th className="filter-input-column">
                                            <input className="form-control" type="date" placeholder='Date'
                                                   id="date" value={this.state.dateFilterValue}
                                                   onChange={(event => this.handleFilterChange(event, 'Date'))}/>
                                        </th>
                                    </tr>
                                    </thead>
                                </table>
                                <table className="w-100 admin-table finance-table">
                                    <tbody>
                                    <tr>
                                        <td>
                                            Total Orders:
                                            <span className="red-font font-weight-bold">{statistics.totalOrders}</span>
                                        </td>
                                        <td>
                                            Total Confirmed Orders:
                                            <span className="red-font font-weight-bold">
                                                {statistics.totalConfirmedOrders}
                                            </span>
                                        </td>
                                        <td>
                                            Total Pay Online Orders:
                                            <span className="red-font font-weight-bold">
                                                {statistics.totalPayOnlineOrders}
                                            </span>
                                        </td>
                                        <td>
                                            Total Pay On Delivery/Pickup Orders:
                                            <span className="red-font font-weight-bold">
                                                {statistics.totalPayOnDeliveryOrders}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="red-font font-weight-bold">
                                            Total Paid Online: <span>Rs. {statistics.totalPaidOnline}</span>
                                        </td>
                                        <td colSpan="2" className="red-font font-weight-bold">
                                            Total Paid On Delivery/Pickup:
                                            <span>Rs. {statistics.totalPaidOnDelivery}</span>
                                        </td>
                                        <td>
                                            <button className="btn red-button">
                                                <CSVLink className="nav-link" data={csvData}
                                                         filename={dateformat(this.state.dateFilterValue, "yyyy-mm-dd") + ".csv"}>
                                                    Download CSV
                                                </CSVLink>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table className="w-100 admin-table">
                                    <thead>
                                    <tr>
                                        <th className="red-font">Date</th>
                                        <th className="red-font">Time</th>
                                        <th className="red-font">Order No</th>
                                        <th className="red-font">Customer</th>
                                        <th className="red-font">Total Price</th>
                                        <th className="red-font">Status</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.orderTableBody()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {order ?
                            <div className="modal fade" id="orderModal" tabIndex="-1" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body order-modal-body">
                                            <table className="w-100">
                                                <tbody>
                                                <tr>
                                                    <td className="grey-font">{(new Date(order.created_at)).toDateString()}</td>
                                                    <td/>
                                                    <td className="red-font">
                                                        Order No: {order.reference}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3" className="font-weight-bold capital-text">
                                                        {order.first_name + ' ' + order.last_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        {order.email}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        {order.phone}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        {order.address}
                                                    </td>
                                                </tr>
                                                {order.delivery ?
                                                    <tr>
                                                        <td>
                                                            Delivery Area
                                                        </td>
                                                        <td/>
                                                        <td className="red-font">
                                                            {order.delivery.area}
                                                        </td>
                                                    </tr>
                                                    : null
                                                }
                                                </tbody>
                                            </table>
                                            <table className="w-100 order-detail-table">
                                                <tbody>
                                                {this.orderModalTableBody(null)}
                                                </tbody>
                                            </table>
                                            <table className="w-100">
                                                <tbody>
                                                <tr className="nunito-sans-font">
                                                    <td>Discount</td>
                                                    <td/>
                                                    <td>
                                                        Rs. ({order.sub_total * (order.promo_code_percentage / 100)})
                                                    </td>
                                                </tr>
                                                <tr className="nunito-sans-font">
                                                    <td>Delivery Charge</td>
                                                    <td/>
                                                    <td>Rs. {order.delivery_charge}</td>
                                                </tr>
                                                <tr>
                                                    <td/>
                                                    <td/>
                                                    <td className="font-weight-bold">
                                                        Total:
                                                        Rs. {(order.sub_total - (order.sub_total * (order.promo_code_percentage / 100))) + order.delivery_charge}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            {order.notes ? <p className="order-status">Notes: {order.notes}</p> : null}
                                            {order.status !== 'Confirmed' ?
                                                <p className="red-font order-status">{order.status}</p> : null}
                                            <p className="order-status">{order.payment_type}</p>
                                            <p className="order-status">{order.delivery_payment_type}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        );
    }

    loadOrders = () => {
        let {branch, user, dateFilterValue} = this.state,
            now = new Date();
        getRequest('/api/orders/branch/' + (user.role_id === 3 ? branch.id : user.branch_id) + '/date/' + dateformat(now, "yyyy-mm-dd"), user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.props.alert.error(response.data.message);
                } else {
                    if (dateFilterValue !== dateformat(new Date(), "yyyy-mm-dd")) {
                        this.setState({orders: response.data.orders});
                    } else {
                        this.setState({orders: response.data.orders, filteredOrders: response.data.orders});
                        this.handleFilterChange(null).then(null);
                    }
                }
            } else {
                this.props.alert.error("An error occurred!");
            }
        });
    }

    orderTableBody = () => {
        let body = [];
        this.state.filteredOrders.filter(order => order.status === "Confirmed").forEach((order, index) => {
            body.push(
                <tr key={index}>
                    <td className="date-column">{(new Date(order.created_at)).toDateString()}</td>
                    <td className="date-column">{(new Date(order.created_at)).toLocaleTimeString()}</td>
                    <td className="order-no-column">{order.reference}</td>
                    <td className="capital-text">{order.first_name + ' ' + order.last_name}</td>
                    <td>{(order.sub_total - (order.sub_total * (order.promo_code_percentage / 100))) + order.delivery_charge}</td>
                    <td>{order.kitchen_status}</td>
                    <td className="action-column red-font">
                        <span data-toggle='modal' data-target="#orderModal"
                              onClick={() => this.changeOrder(order)}>
                            View Full Order
                        </span>
                    </td>
                </tr>
            )
        });
        return body;
    }

    changeOrder = (order) => {
        this.setState({order: order});
    };

    orderModalTableBody = (order) => {
        let body = [];
        order = order ? order : this.state.order;
        if (order) {
            order.order_products.forEach((product, index) => {
                body.push(
                    <tr key={index} className="nunito-sans-font">
                        <td>{product.food.name}</td>
                        <td>Rs. {product.price} x {product.quantity}</td>
                        <td>Rs. {product.price * product.quantity}</td>
                    </tr>
                )
            });
            order.order_offers.forEach((offer) => {
                body.push(
                    <tr key={body.length} className="nunito-sans-font">
                        <td>{offer.offer.name}</td>
                        <td>Rs. {offer.price} x {offer.quantity}</td>
                        <td>Rs. {offer.price * offer.quantity}</td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleFilterChange = async (event, action) => {
        if (event) {
            event.preventDefault();
        }
        let orderNoFilterValue = action === 'Order No' && event ? event.target.value : this.state.orderNoFilterValue,
            customerFilterValue = action === 'Customer' && event ? event.target.value : this.state.customerFilterValue,
            dateFilterValue = action === 'Date' && event ? event.target.value : this.state.dateFilterValue,
            paymentTypeFilterValue = action === 'Payment Type' && event ? event.target.value : this.state.paymentTypeFilterValue,
            {branch, user, orders, dateFilteredOrders} = this.state,
            filteredOrders;

        if (dateFilterValue !== dateformat(new Date(), "yyyy-mm-dd")) {
            if (action === 'Date') {
                await getRequest('/api/orders/branch/' + (user.role_id === 3 ? branch.id : user.branch_id) + '/date/' + dateFilterValue, user.api_token).then(response => {
                    if (response.status === 200) {
                        if (response.data.error) {
                            this.props.alert.error(response.data.message);
                        } else {
                            orders = response.data.orders;
                            dateFilteredOrders = orders;
                        }
                    } else {
                        this.props.alert.error("An error occurred!");
                    }
                });
            } else {
                orders = dateFilteredOrders;
            }
        }

        if (paymentTypeFilterValue !== "All") {
            orders = orders.filter(function (order) {
                return !!(order.payment_type.includes(paymentTypeFilterValue));
            });
        }

        if (!orderNoFilterValue && !customerFilterValue) {
            filteredOrders = orders;
        } else if (orderNoFilterValue && !customerFilterValue) {
            filteredOrders = orders.filter(function (order) {
                return !!(order.reference.includes(orderNoFilterValue));
            });
        } else if (!orderNoFilterValue && customerFilterValue) {
            filteredOrders = orders.filter(function (order) {
                return !!((order.first_name + ' ' + order.last_name).toLowerCase().includes(customerFilterValue.toLowerCase()));
            });
        } else {
            filteredOrders = orders.filter(function (order) {
                return !!((order.first_name + ' ' + order.last_name).toLowerCase().includes(customerFilterValue.toLowerCase()) && order.reference.includes(orderNoFilterValue));
            });
        }
        this.setState({
            orderNoFilterValue: orderNoFilterValue,
            customerFilterValue: customerFilterValue,
            dateFilterValue: dateFilterValue,
            filteredOrders: filteredOrders,
            dateFilteredOrders: dateFilteredOrders,
            paymentTypeFilterValue: paymentTypeFilterValue
        });
        this.calculateStatistics();
    };

    calculateStatistics = () => {
        let statistics = {
                totalOrders: 0,
                totalConfirmedOrders: 0,
                totalPayOnlineOrders: 0,
                totalPayOnDeliveryOrders: 0,
                totalPaidOnline: 0,
                totalPaidOnDelivery: 0
            },
            orders = [];
        statistics.totalOrders = this.state.dateFilteredOrders.length;
        this.state.dateFilteredOrders.filter(order => order.status === "Confirmed").forEach((order) => {
            statistics.totalConfirmedOrders += 1;
            if (order.payment_type === "Pay Online") {
                statistics.totalPayOnlineOrders += 1;
                statistics.totalPaidOnline += (order.sub_total - (order.sub_total * (order.promo_code_percentage / 100))) + order.delivery_charge;
            } else {
                statistics.totalPayOnDeliveryOrders += 1;
                statistics.totalPaidOnDelivery += (order.sub_total - (order.sub_total * (order.promo_code_percentage / 100))) + order.delivery_charge;
            }
            orders.push([
                (new Date(order.created_at)).toDateString(),
                (new Date(order.created_at)).toLocaleTimeString(),
                order.reference,
                order.first_name + ' ' + order.last_name,
                (order.sub_total - (order.sub_total * (order.promo_code_percentage / 100))) + order.delivery_charge,
                order.kitchen_status,
                order.payment_type
            ])
        });
        let csvData = [
            [
                "Total Orders", statistics.totalOrders,
                "Total Confirmed Orders", statistics.totalConfirmedOrders,
                "Total Pay Online Orders", statistics.totalPayOnlineOrders,
                "Total Pay Delivery/Pickup Orders", statistics.totalPayOnDeliveryOrders
            ],
            [
                "Total Paid Online", "Rs. " + statistics.totalPaidOnline,
                "Total Paid On Delivery/Pickup", "Rs. " + statistics.totalPaidOnDelivery
            ],
            [],
            ["Date", "Time", "Order No", "Customer", "Total Price", "Status", "Payment Type"]
        ]
        csvData = csvData.concat(orders);
        this.setState({statistics: statistics, csvData: csvData});
    }
}

export default withAlert()(withRouter(Finance));