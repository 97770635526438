import {sessionService} from 'redux-react-session';

export const createUser = async (user) => {
    user.time = new Date();
    sessionService.saveUser(user);
};

export const getUser = async (history) => {
    return sessionService.loadUser();
};

export const deleteUser = async () => {
    sessionService.deleteUser();
};