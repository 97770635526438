import React, {Component} from 'react';
import {withAlert} from "react-alert";
import {withRouter} from 'react-router-dom';
import {postRequest} from "../routes/Routes";
import {createUser} from "../auth/Auth";
import {loadBranches, updateBranch} from "../common/Common";
import Loading from '../components/Loading';
import '../assets/css/Home.css';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {error: false, errorMessage: null, loading: false, branches: [], loadingBranches: true};
    }

    componentDidMount() {
        if (!this.props.isLoginComponent) {
            loadBranches().then(data => {
                this.setState({branches: data, loadingBranches: false});
            }).catch(() => null);
        }
    }

    render() {
        return (
            <div className="home-container">
                <div className={this.props.isLoginComponent ? "popup-container login-container" : "popup-container"}>
                    {this.props.isLoginComponent ? [
                        <h1 className="red-font" key="heading">Login</h1>,
                        <form onSubmit={this.loginFunction} key="form">
                            <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <input type="email" className="form-control" id="email" required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input className="form-control" type="password" id="password" minLength="8" required/>
                            </div>
                            {this.state.error &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMessage}
                            </div>}
                            <button className="btn red-button login-button">
                                {this.state.loading ?
                                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                          aria-hidden="true"/>
                                    : null
                                }
                                Login
                            </button>
                        </form>
                    ] : [
                        <h1 className="red-font" key="heading">Welcome to Burger Hut!</h1>,
                        <p key="paragraph" className="nunito-sans-font font-weight-bold">Select your closest outlet</p>,
                        this.state.loadingBranches ?
                            <Loading/>
                            : this.getBranchBody()
                    ]}
                </div>
            </div>
        );
    }

    loginFunction = (event) => {
        this.setState({loading: true});
        event.preventDefault();
        let values = {
            email: event.target.email.value,
            password: event.target.password.value,
            endpoint: '/api/login'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({error: true, errorMessage: response.data.message});
                } else {
                    createUser(response.data.user).then(() => {
                        this.props.history.go(
                            this.props.history.push({pathname: "/"})
                        );
                    });
                }
            } else {
                this.setState({error: true, errorMessage: 'An error occurred!'});
            }
            this.setState({loading: false});
        });
    }

    getBranchBody = () => {
        let firstColumn = [];
        let secondColumn = [];
        let thirdColumn = [];
        this.state.branches.forEach((branch, index) => {
            if ((index + 1) % 3 === 0) {
                thirdColumn.push(this.getBranchCode(branch, index))
            } else if ((index + 1) % 2 === 0) {
                secondColumn.push(this.getBranchCode(branch, index))
            } else {
                firstColumn.push(this.getBranchCode(branch, index))
            }
        });

        return (
            <div className="d-flex bd-highlight w-100 branch-list-component" key="branches">
                <div className="p-2 flex-fill bd-highlight">
                    {firstColumn}
                </div>
                {secondColumn.length > 0 ?
                    <div className="p-2 flex-fill bd-highlight">
                        {secondColumn}
                    </div>
                    : null
                }
                {thirdColumn.length > 0 ?
                    <div className="p-2 flex-fill bd-highlight">
                        {thirdColumn}
                    </div>
                    : null
                }
            </div>
        );
    };

    getBranchCode = (branch, index) => {
        return (
            <div key={index}>
                <button className="btn white-button login-button capital-text"
                        onClick={(event) => updateBranch(branch, this.props.history)}>
                    {branch.name}
                </button>
            </div>
        );
    };
}

export default withAlert()(withRouter(Home));