import React, {Component} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import Select from "react-select";
import {isMobile, isTablet} from 'react-device-detect';
import {getBranch, loadBranchDeliveries, loadBranchOffers, updateAddress} from "../common/Common";
import FoodList from "../components/FoodList";
import Footer from "./Footer";
import '../assets/css/Address.css';

import deliveryBackground from '../assets/images/delivery-background.jpg';
import pickupBackground from '../assets/images/pickup-background.jpg';

class Address extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branch: null,
            offers: [],
            deliveries: [],
            orderType: 'delivery-tab',
            error: false,
            errorMessage: null,
        };
    }

    componentDidMount() {
        getBranch().then(branch => {
            loadBranchDeliveries(branch.id).then(deliveries => {
                this.setState({branch: branch, deliveries: deliveries});
            }).catch(() => null);
            loadBranchOffers(branch.id).then(offers => {
                this.setState({branch: branch, offers: offers.slice(0, 3)});
            }).catch(() => null);
        }).catch(() => null);
    }

    render() {
        if (this.state.branch) {
            return ([
                <div key="body">
                    <div className="address-header">
                        {this.state.orderType === 'delivery-tab' ?
                            isMobile && !isTablet ?
                                <img src={deliveryBackground} alt="Delivery Background"/>
                                : <img src={deliveryBackground} alt="Delivery Background"/>
                            : <img src={pickupBackground} alt="Pickup Background"/>
                        }
                    </div>
                    <div className="container address-container">
                        <div className="order-type-container">
                            <ul className="nav nav-tabs" id="orderTypeTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="delivery-tab"
                                       data-toggle="tab" href="#delivery"
                                       role="tab" aria-controls="delivery" onClick={this.actionClick}>
                                        Delivery
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="pickup-tab"
                                       data-toggle="tab" href="#pickup" role="tab"
                                       aria-controls="pickup-tab" onClick={this.actionClick}>
                                        {isMobile && !isTablet ?
                                            'Takeaway'
                                            : 'Takeaway / Pickup'
                                        }
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id="orderTypeTabContent">
                                <div className="tab-pane fade show active" id="delivery" role="tabpanel"
                                     aria-labelledby="delivery-tab">
                                    <form onSubmit={this.formSubmit} className="delivery-form">
                                        <div className="d-flex bd-highlight w-100 delivery-form-container">
                                            <div className="p-2 flex-fill bd-highlight form-delivery-area-container">
                                                <div className="form-group">
                                                    <Select className="w-100 select"
                                                            placeholder="Select Area"
                                                            name="deliveryLocation" id="deliveryLocation"
                                                            options={this.state.deliveries}
                                                            getOptionValue={option => option['id']}
                                                            getOptionLabel={option => option['area']} required/>
                                                </div>
                                            </div>
                                            <div className="p-2 flex-fill bd-highlight form-address-container">
                                                <div className="form-group">
                                                    <input className="form-control" type="text"
                                                           placeholder='Address (Ex. No. 01, Kinross Avenue, Marine Drive)'
                                                           id="address" required/>
                                                </div>
                                            </div>
                                            {isMobile && !isTablet ?
                                                null
                                                : <div className="p-2 flex-fill bd-highlight">
                                                    <button className="btn red-button" type="submit">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                             fill="currentColor" className="bi bi-arrow-right-short"
                                                             viewBox="0 0 16 16">
                                                            <path fillRule="evenodd"
                                                                  d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                        {isMobile && !isTablet ?
                                            <button className="btn red-button mobile-button" type="submit">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                     fill="currentColor" className="bi bi-arrow-right-short"
                                                     viewBox="0 0 16 16">
                                                    <path fillRule="evenodd"
                                                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                </svg>
                                            </button>
                                            : null
                                        }
                                        {this.state.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>}
                                    </form>
                                </div>
                                <div className="tab-pane fade" id="pickup" role="tabpanel"
                                     aria-labelledby="pickup-tab">
                                    <form onSubmit={this.formSubmit}>
                                        <div className="d-flex bd-highlight w-100">
                                            <div className="p-2 flex-fill bd-highlight form-branch-address-container">
                                                <div className="form-group">
                                                    <input className="form-control" type="text"
                                                           placeholder='Enter Branch Location'
                                                           id="address" value={this.state.branch.address} disabled/>
                                                </div>
                                            </div>
                                            <div className="p-2 flex-fill bd-highlight">
                                                <button className="btn red-button" type="submit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                         fill="currentColor" className="bi bi-arrow-right-short"
                                                         viewBox="0 0 16 16">
                                                        <path fillRule="evenodd"
                                                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="offers">
                            <h1>Offers & Promotions</h1>
                            {this.state.offers.length > 0 ?
                                <FoodList offers={this.state.offers}/>
                                : <div className="no-offers-div">No Offers</div>
                            }
                            <NavLink className="nav-link" to="/offers">
                                <button className="btn my-2 my-sm-0 red-button more-deals-button">
                                    More Deals
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>,
                <div id="footer" key="footer">
                    <Footer/>
                </div>
            ])
        } else {
            return null;
        }
    }

    actionClick = (event) => {
        event.preventDefault();
        this.setState({orderType: event.target.id});
    };

    formSubmit = (event) => {
        event.preventDefault();
        if (this.state.orderType === 'delivery-tab') {
            if (event.target.deliveryLocation.value === '') {
                this.setState({
                    error: true,
                    errorMessage: "Select a delivery location!"
                });
                return;
            }
            updateAddress({
                deliveryLocation: event.target.deliveryLocation.value,
                address: event.target.address.value
            }).then(() => {
                this.props.history.go(
                    this.props.history.push({pathname: "/menu"})
                )
            })
        } else {
            this.props.history.go(
                this.props.history.push({pathname: "/menu"})
            )
        }
    }
}

export default withRouter(Address);