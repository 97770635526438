import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {getUser} from "../auth/Auth";
import {getRequest, putRequest} from "../routes/Routes";
import {getBranch} from "../common/Common";
import {withAlert} from "react-alert";
import dateformat from "dateformat";
import '../assets/css/Admin.css';

class Portal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            filteredOrders: [],
            user: null,
            branch: null,
            order: null,
            orderNoFilterValue: '',
            customerFilterValue: '',
            dateFilterValue: dateformat(new Date(), "yyyy-mm-dd"),
            dateFilteredOrders: [],
            cancelOrder: false,
            loading: false,
            cancelOrderCodeMessage: null,
            cancelOrderCodeSuccessMessage: null
        };
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            getBranch().then(branch => {
                this.setState({branch: branch});
                this.loadOrders();
            }).catch(() => null);
            this.setState({user: data});
        }).catch(() => null);
        this.interval = setInterval(() => {
            this.loadOrders();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        let {order, cancelOrder, cancelOrderCodeMessage, cancelOrderCodeSuccessMessage, loading} = this.state;
        return (
            <div className="admin-container nunito-sans-font">
                <div className="admin-container-navbar">
                    <div className="vertical-nav">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                             aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-incoming-orders-tab" data-toggle="pill"
                               href="#v-pills-incoming-orders" role="tab" aria-controls="v-pills-incoming-orders"
                               aria-selected="true">Incoming Orders</a>
                            <a className="nav-link" id="v-pills-on-process-orders-tab" data-toggle="pill"
                               href="#v-pills-on-process-orders" role="tab" aria-controls="v-pills-on-process-orders"
                               aria-selected="true">On Process Orders</a>
                            <a className="nav-link" id="v-pills-order-history-tab" data-toggle="pill"
                               href="#v-pills-order-history" role="tab" aria-controls="v-pills-order-history"
                               aria-selected="false">Order History</a>
                        </div>
                    </div>
                    <div className="nav-detail-container portal-nav-detail-container">
                        <div className="tab-content container" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-incoming-orders" role="tabpanel"
                                 aria-labelledby="v-pills-incoming-orders-tab">
                                <table className="w-100 admin-table">
                                    <thead>
                                    <tr>
                                        <th className="red-font">Time</th>
                                        <th className="red-font">Order No</th>
                                        <th className="red-font">Customer</th>
                                        <th className="red-font">Total Price</th>
                                        <th className="red-font">Payment Type</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.latestOrderTableBody('Incoming')}
                                    </tbody>
                                </table>
                            </div>
                            <div className="tab-pane fade" id="v-pills-on-process-orders" role="tabpanel"
                                 aria-labelledby="v-pills-on-process-orders-tab">
                                <table className="w-100 admin-table">
                                    <thead>
                                    <tr>
                                        <th className="red-font">Time</th>
                                        <th className="red-font">Order No</th>
                                        <th className="red-font">Customer</th>
                                        <th className="red-font">Total Price</th>
                                        <th className="red-font">Payment Type</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.latestOrderTableBody('On Process')}
                                    </tbody>
                                </table>
                            </div>
                            <div className="tab-pane fade" id="v-pills-order-history" role="tabpanel"
                                 aria-labelledby="v-pills-order-history-tab">
                                <table className="w-100 admin-table">
                                    <thead>
                                    <tr>
                                        <th className="admin-table-heading"><h1>Order History</h1></th>
                                        <th className="filter-input-column">
                                            <input className="form-control" type="text" placeholder='Order No'
                                                   id="orderNo" value={this.state.orderNoFilterValue}
                                                   onChange={(event => this.handleFilterChange(event, 'Order No'))}/>
                                        </th>
                                        <th className="filter-input-column">
                                            <input className="form-control" type="text" placeholder='Customer Name'
                                                   id="name" value={this.state.customerFilterValue}
                                                   onChange={(event => this.handleFilterChange(event, 'Customer'))}/>
                                        </th>
                                        <th className="filter-input-column">
                                            <input className="form-control" type="date" placeholder='Date'
                                                   id="date" value={this.state.dateFilterValue}
                                                   onChange={(event => this.handleFilterChange(event, 'Date'))}/>
                                        </th>
                                    </tr>
                                    </thead>
                                </table>
                                <table className="w-100 admin-table">
                                    <thead>
                                    <tr>
                                        <th className="red-font">Date</th>
                                        <th className="red-font">Time</th>
                                        <th className="red-font">Order No</th>
                                        <th className="red-font">Customer</th>
                                        <th className="red-font">Total Price</th>
                                        <th className="red-font">Status</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.orderTableBody()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {order ?
                            <div className="modal fade" id="orderModal" tabIndex="-1" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body order-modal-body">
                                            <table className="w-100">
                                                <tbody>
                                                <tr>
                                                    <td className="grey-font">{(new Date(order.created_at)).toDateString()}</td>
                                                    <td/>
                                                    <td className="red-font">
                                                        Order No: {order.reference}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3" className="font-weight-bold capital-text">
                                                        {order.first_name + ' ' + order.last_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        {order.email}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        {order.phone}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="3">
                                                        {order.address}
                                                    </td>
                                                </tr>
                                                {order.delivery ?
                                                    <tr>
                                                        <td>
                                                            Delivery Area
                                                        </td>
                                                        <td/>
                                                        <td className="red-font">
                                                            {order.delivery.area}
                                                        </td>
                                                    </tr>
                                                    : null
                                                }
                                                </tbody>
                                            </table>
                                            <table className="w-100 order-detail-table">
                                                <tbody>
                                                {this.orderModalTableBody(null)}
                                                </tbody>
                                            </table>
                                            <table className="w-100">
                                                <tbody>
                                                <tr className="nunito-sans-font">
                                                    <td>Discount</td>
                                                    <td/>
                                                    <td>
                                                        Rs. ({order.sub_total * (order.promo_code_percentage / 100)})
                                                    </td>
                                                </tr>
                                                <tr className="nunito-sans-font">
                                                    <td>Delivery Charge</td>
                                                    <td/>
                                                    <td>Rs. {order.delivery_charge}</td>
                                                </tr>
                                                <tr>
                                                    <td/>
                                                    <td/>
                                                    <td className="font-weight-bold">
                                                        Total:
                                                        Rs. {(order.sub_total - (order.sub_total * (order.promo_code_percentage / 100))) + order.delivery_charge}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            {order.notes ? <p className="order-status">Notes: {order.notes}</p> : null}
                                            {order.status !== 'Confirmed' ?
                                                <p className="red-font order-status">{order.status}</p> : null}
                                            <p className="order-status">{order.payment_type}</p>
                                            <p className="order-status">{order.delivery_payment_type}</p>
                                        </div>
                                        <div className="modal-footer order-modal-footer">
                                            {order.kitchen_status === 'Incoming' ?
                                                [
                                                    <button type="button"
                                                            data-dismiss="modal"
                                                            className="btn red-button kitchen-status-button"
                                                            key="onProcessButton"
                                                            onClick={() => this.updateKitchenStatus(order, 'On Process')}>
                                                        On Process
                                                    </button>,
                                                    <button type="button"
                                                            className="btn white-button kitchen-status-button"
                                                            data-dismiss="modal"
                                                            key="cancelButton" data-toggle='modal'
                                                            data-target="#cancelOrderModal"
                                                            onClick={() => this.updateKitchenStatus(order, 'Cancelled')}>
                                                        Cancel
                                                    </button>
                                                ]
                                                : order.kitchen_status === 'On Process' ?
                                                    [
                                                        <button type="button"
                                                                data-dismiss="modal"
                                                                className="btn red-button kitchen-status-button"
                                                                key="deliveredButton"
                                                                onClick={() => this.updateKitchenStatus(order, 'Delivered')}>
                                                            Delivered
                                                        </button>,
                                                        <button type="button"
                                                                className="btn white-button kitchen-status-button"
                                                                data-dismiss="modal"
                                                                key="cancelButton" data-toggle='modal'
                                                                data-target="#cancelOrderModal"
                                                                onClick={() => this.updateKitchenStatus(order, 'Cancelled')}>
                                                            Cancel
                                                        </button>
                                                    ]
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        {cancelOrder ?
                            <div className="modal fade" id="cancelOrderModal" tabIndex="-1" aria-hidden="true"
                                 data-backdrop="static">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body order-modal-body">
                                            <h1>Cancel Order Code</h1>
                                            <form onSubmit={this.checkCode}>
                                                <div className="form-group">
                                                    <input type="number" className="form-control" id="code" required
                                                           placeholder="Cancel Order Code" minLength="4" maxLength="4"/>
                                                </div>
                                                {cancelOrderCodeMessage &&
                                                <div className="alert alert-danger" role="alert">
                                                    {cancelOrderCodeMessage}
                                                </div>}
                                                {cancelOrderCodeSuccessMessage &&
                                                <div className="alert alert-success" role="alert">
                                                    {cancelOrderCodeSuccessMessage}
                                                </div>}
                                                <button className="btn red-button login-button" type="submit"
                                                        disabled={loading}>
                                                    {loading ?
                                                        <span className="spinner-grow spinner-grow-sm ml-1"
                                                              role="status"
                                                              aria-hidden="true"/>
                                                        : null
                                                    } Confirm
                                                </button>
                                            </form>
                                        </div>
                                        <div className="modal-footer order-modal-footer">
                                            <button type="button" className="btn white-button table-button"
                                                    data-dismiss="modal" onClick={() => {
                                                this.setState({
                                                    cancelOrder: false,
                                                    cancelOrderCodeMessage: null,
                                                    cancelOrderCodeSuccessMessage: null
                                                });
                                            }}>
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        );
    }

    loadOrders = () => {
        let {branch, user, dateFilterValue} = this.state,
            now = new Date();
        getRequest('/api/orders/branch/' + (user.role_id === 3 ? branch.id : user.branch_id) + '/date/' + dateformat(now, "yyyy-mm-dd"), user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.props.alert.error(response.data.message);
                } else {
                    if (dateFilterValue !== dateformat(new Date(), "yyyy-mm-dd")) {
                        this.setState({orders: response.data.orders});
                    } else {
                        this.setState({orders: response.data.orders, filteredOrders: response.data.orders});
                        this.handleFilterChange(null).then(null);
                    }
                }
            } else {
                this.props.alert.error("An error occurred!");
            }
        });
    }

    orderTableBody = () => {
        let body = [];
        this.state.filteredOrders.forEach((order, index) => {
            body.push(
                <tr key={index}>
                    <td className="date-column">{(new Date(order.created_at)).toDateString()}</td>
                    <td className="date-column">{(new Date(order.created_at)).toLocaleTimeString()}</td>
                    <td className="order-no-column">{order.reference}</td>
                    <td className="capital-text">{order.first_name + ' ' + order.last_name}</td>
                    <td>{(order.sub_total - (order.sub_total * (order.promo_code_percentage / 100))) + order.delivery_charge}</td>
                    <td>{order.kitchen_status}</td>
                    <td className="action-column red-font">
                        <span data-toggle='modal' data-target="#orderModal"
                              onClick={() => this.changeOrder(order)}>
                            View Full Order
                        </span>
                    </td>
                </tr>
            )
        });
        return body;
    }

    changeOrder = (order) => {
        this.setState({order: order});
    };

    orderModalTableBody = (order) => {
        let body = [];
        order = order ? order : this.state.order;
        if (order) {
            order.order_products.forEach((product, index) => {
                body.push(
                    <tr key={index} className="nunito-sans-font">
                        <td>{product.food.name}</td>
                        <td>Rs. {product.price} x {product.quantity}</td>
                        <td>Rs. {product.price * product.quantity}</td>
                    </tr>
                )
            });
            order.order_offers.forEach((offer) => {
                body.push(
                    <tr key={body.length} className="nunito-sans-font">
                        <td>{offer.offer.name}</td>
                        <td>Rs. {offer.price} x {offer.quantity}</td>
                        <td>Rs. {offer.price * offer.quantity}</td>
                    </tr>
                )
            });
        }
        return body;
    }

    latestOrderTableBody = (kitchenStatus) => {
        let {orders} = this.state,
            body = [];
        orders.filter(order => order.status === "Confirmed").forEach((filteredOrder) => {
            if (filteredOrder.kitchen_status === kitchenStatus) {
                body.push(
                    <tr key={filteredOrder.id}>
                        <td className="date-column">{(new Date(filteredOrder.created_at)).toLocaleTimeString()}</td>
                        <td className="order-no-column">{filteredOrder.reference}</td>
                        <td className="capital-text">{filteredOrder.first_name + ' ' + filteredOrder.last_name}</td>
                        <td>{(filteredOrder.sub_total - (filteredOrder.sub_total * (filteredOrder.promo_code_percentage / 100))) + filteredOrder.delivery_charge}</td>
                        <td>{filteredOrder.payment_type}</td>
                        <td className="action-column red-font">
                            <span data-toggle='modal' data-target="#orderModal"
                                  onClick={() => this.changeOrder(filteredOrder)}>
                            View Full Order
                            </span>
                        </td>
                    </tr>
                )
            }
        });
        return body;
    }

    updateKitchenStatus = (order, status) => {
        let {user, cancelOrder} = this.state;
        if (status === 'Cancelled' && !cancelOrder) {
            this.setState({order: order, cancelOrder: true});
            return;
        }
        order.status = status === 'Cancelled' ? status : order.status;
        order.kitchen_status = status;
        order.endpoint = '/api/orders/' + order.id;
        order.token = user.api_token;
        putRequest(order).then(async response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.props.alert.error(response.data.message);
                } else {
                    this.props.alert.success(response.data.message);
                    this.loadOrders();
                }
            } else {
                this.props.alert.error("An error occurred!");
            }
        });
    }

    checkCode = (event) => {
        event.preventDefault();
        const code = event.target.code.value;
        if (code.toString().length === 4) {
            this.setState({loading: true});
            if (code === '8548') {
                this.updateKitchenStatus(this.state.order, "Cancelled");
                this.setState({
                    cancelOrderCodeMessage: null,
                    cancelOrderCodeSuccessMessage: "Successfully Cancelled Order",
                });
            } else {
                this.setState({cancelOrderCodeMessage: "Incorrect password", cancelOrderCodeSuccessMessage: null});
            }
        } else {
            this.setState({cancelOrderCodeMessage: "Please enter 4 digits", cancelOrderCodeSuccessMessage: null});
        }
        this.setState({loading: false});
    }

    handleFilterChange = async (event, action) => {
        if (event) {
            event.preventDefault();
        }
        let orderNoFilterValue = action === 'Order No' && event ? event.target.value : this.state.orderNoFilterValue,
            customerFilterValue = action === 'Customer' && event ? event.target.value : this.state.customerFilterValue,
            dateFilterValue = action === 'Date' && event ? event.target.value : this.state.dateFilterValue,
            {branch, user, orders, dateFilteredOrders} = this.state,
            filteredOrders;

        if (dateFilterValue !== dateformat(new Date(), "yyyy-mm-dd")) {
            if (action === 'Date') {
                await getRequest('/api/orders/branch/' + (user.role_id === 3 ? branch.id : user.branch_id) + '/date/' + dateFilterValue, user.api_token).then(response => {
                    if (response.status === 200) {
                        if (response.data.error) {
                            this.props.alert.error(response.data.message);
                        } else {
                            orders = response.data.orders;
                            dateFilteredOrders = orders;
                        }
                    } else {
                        this.props.alert.error("An error occurred!");
                    }
                });
            } else {
                orders = dateFilteredOrders;
            }
        }

        if (!orderNoFilterValue && !customerFilterValue) {
            filteredOrders = orders;
        } else if (orderNoFilterValue && !customerFilterValue) {
            filteredOrders = orders.filter(function (order) {
                return !!(order.reference.includes(orderNoFilterValue));
            });
        } else if (!orderNoFilterValue && customerFilterValue) {
            filteredOrders = orders.filter(function (order) {
                return !!((order.first_name + ' ' + order.last_name).toLowerCase().includes(customerFilterValue.toLowerCase()));
            });
        } else {
            filteredOrders = orders.filter(function (order) {
                return !!((order.first_name + ' ' + order.last_name).toLowerCase().includes(customerFilterValue.toLowerCase()) && order.reference.includes(orderNoFilterValue));
            });
        }
        this.setState({
            orderNoFilterValue: orderNoFilterValue,
            customerFilterValue: customerFilterValue,
            dateFilterValue: dateFilterValue,
            filteredOrders: filteredOrders,
            dateFilteredOrders: dateFilteredOrders
        });
    };
}

export default withAlert()(withRouter(Portal));