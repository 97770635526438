import React, {Component} from 'react';
import Footer from "./Footer";
import '../assets/css/AboutUs.css';

class PrivacyPolicy extends Component {
    render() {
        return ([
            <div key="body">
                <div className="about-us-container container">
                    <div className="about-us-detail-container">
                        <h1>Terms & Conditions</h1>
                        <p className="nunito-sans-font">
                            This terms & conditions applies to the website www.burgerhut.lk hereinafter referred to as
                            either Company, We, Us, Or ours. We guarantee the security of personal information provided
                            by the customer on every transaction made through the website. The terms put forward are
                            applicable to all users of this website and in case of a disagreement with the terms the
                            customer is encouraged to cease the usage of the website.
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Refund of order</h1>
                        <p className="nunito-sans-font">
                            The customer would only be applicable to a refund of payment if the following terms are met.
                        </p>
                        <ul className="nunito-sans-font">
                            <li>On the occasion that the food purchased or delivered is expired.</li>
                            <li>On the occasion that items ordered are missing.</li>
                        </ul>
                        <p className="nunito-sans-font">
                            Items will only be replaced if the bill of proof is provided alongside photographic proof of
                            expired or missing item.
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Currency used on website</h1>
                        <p className="nunito-sans-font">
                            The prices mentioned on the website are all in local currency (LKR).
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Financial security</h1>
                        <p className="nunito-sans-font">
                            Transactions made through our website follow a secure payment gateway operated by Seylan
                            bank PLC. Financial information are processed and verified securely through the
                            aforementioned financial institution whilst using SSL encryption.
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Financial refunds</h1>
                        <p className="nunito-sans-font">
                            There will be no refunds made through the website. If a dispute rises due to an order made
                            through the website the customer is encouraged to get in contact with the relevant branch
                            and communicate the issue at hand.
                        </p>
                    </div>
                    <div className="about-us-detail-container">
                        <h1>Policy on discounts</h1>
                        <p className="nunito-sans-font">
                            The discounts provided are not applicable to existing promotions or any other promotional
                            material put forward by the company.
                        </p>
                    </div>
                </div>
            </div>,
            <div id="footer" key="footer">
                <Footer/>
            </div>
        ])
    }
}

export default PrivacyPolicy;