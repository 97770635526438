import React, {Component} from 'react';
import CartComponent from "../components/CartComponent";
import SummaryComponent from "../components/SummaryComponent";
import '../assets/css/Cart.css';

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {count: 0};
    }

    render() {
        return (
            <div className="container">
                <div className="d-flex bd-highlight w-100">
                    <div className="p-2 flex-fill bd-highlight cart-component">
                        <CartComponent isCartPage={true} updateState={this.updateState}/>
                    </div>
                    <div className="p-2 flex-fill bd-highlight cart-summary-container desktop-cart-summary-container">
                        <SummaryComponent isCartPage={true} count={this.state.count}/>
                    </div>
                </div>
            </div>
        )
    }

    updateState = () => {
        let {count} = this.state;
        this.setState({count: ++count});
        this.props.updateState();
    }
}

export default Cart;